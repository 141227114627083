import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidationSchema } from "src/validations/loginSchema";
import { useLocalSignInByAdminMutation } from "src/graphql/generated";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/constants";
import { useAuthContext } from "src/context/AuthContext";

interface FormValues {
  email: string;
  password: string;
}

function LoginForm() {
  const { onSuccessAuth } = useAuthContext();
  const navigate = useNavigate();

  const [signInMutation, { loading }] = useLocalSignInByAdminMutation();

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(loginValidationSchema),
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = methods;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = (event: any) => {
    setShowPassword(!showPassword);
    event.preventDefault();
  };

  const onSubmit = (formValues: FormValues) => {
    signInMutation({
      variables: {
        schema: {
          ...formValues,
        },
      },
      onError: (error) => {
        if (error) {
          toast.error(error.message);
        }
      },
      onCompleted: (response) => {
        if (response) {
          onSuccessAuth(true);
          navigate(PATHS.clinics);
        }
      },
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="email-login">Email</InputLabel>
          <OutlinedInput
            id="email-login"
            placeholder="info@email.com"
            fullWidth
            autoComplete="email"
            error={Boolean(errors.email)}
            {...register("email")}
          />
          {errors?.email && (
            <FormHelperText error id="standard-weight-helper-text-email-login">
              {errors?.email?.message?.toString()}
            </FormHelperText>
          )}
        </Stack>
        <Stack spacing={1}>
          <InputLabel htmlFor="password-login">Password</InputLabel>
          <OutlinedInput
            fullWidth
            error={Boolean(errors.password)}
            id="-password-login"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
            placeholder="********"
            {...register("password")}
          />
          {errors.password && (
            <FormHelperText
              error
              id="standard-weight-helper-text-password-login"
            >
              {errors?.password?.message?.toString()}
            </FormHelperText>
          )}
        </Stack>

        <Stack>
          <Button
            disabled={loading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Login
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
export default LoginForm;
