import GradingIcon from "@mui/icons-material/Grading";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

export const PATHS = {
  auth: "/auth",
  clinics: "/",
  updateSid: "/update-sid",
  constraints: "/constraints",
  updateConstraints: "/update-constraints",
};

export const JWT = "admin_json_web_token";

export const drawerWidth = 240;

export const navLinkItems: { text: string; icon: JSX.Element; path: string }[] =
  [
    {
      text: "Clinics",
      icon: <FmdGoodIcon fontSize="small" />,
      path: PATHS.clinics,
    },
    {
      text: "Limits",
      icon: <GradingIcon fontSize="small" />,
      path: PATHS.constraints,
    },
  ];

export const DEFAULT_CLINICS_LIMIT = 10;

export const VALIDATION_TEXT_ERROR = {
  fieldRequired: "Required Field",
  incorrectEmail: "Incorrect e-mail address",
  passwordMin: "Password must contain minimum 8 symbols",
};
