type AuthStateType = {
  isAuth: boolean;
  isInit: boolean;
};

type ActionType =
  | { type: "SUCCESS_AUTH"; payload: { stateAuth: boolean } }
  | { type: "INIT" };

export const actionTypes = {
  SUCCESS_AUTH: "SUCCESS_AUTH",
  INIT: "INIT",
} as const;

export function authReducer(state: AuthStateType, action: ActionType) {
  switch (action.type) {
    case actionTypes.INIT: {
      return { ...state, isInit: true };
    }
    case actionTypes.SUCCESS_AUTH: {
      return {
        ...state,
        isAuth: action.payload.stateAuth,
        isInit: true,
      };
    }

    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}
