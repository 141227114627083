import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUpdateClinicConstraintsMutation } from "src/graphql/generated";
import { yupResolver } from "@hookform/resolvers/yup";
import { FIND_AND_COUNT_MANY_CONSTRAINTS_BY_ADMIN } from "src/graphql/queries/findAndCountManyClinicsByAdmin.gql";
import { PATHS } from "src/constants";
import { toast } from "react-toastify";
import { updateClinicConstraintsSchema } from "src/validations/updateClinicConstraintsSchema";

interface FormValues {
  id: string;
  mailLimit: number;
  mailSent: number;
  smsLimit: number;
  smsSent: number;
}

function UpdateConstraintsPage() {
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const id = query.get("id") as string;
  const mailLimit = Number(query.get("mailLimit"));
  const mailSent = Number(query.get("mailSent"));
  const smsLimit = Number(query.get("smsLimit"));
  const smsSent = Number(query.get("smsSent"));

  const [updateConstraintsMutation, { loading }] =
    useUpdateClinicConstraintsMutation();

  const methods = useForm<FormValues>({
    resolver: yupResolver(updateClinicConstraintsSchema),
    defaultValues: {
      id,
      mailLimit,
      mailSent,
      smsLimit,
      smsSent,
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = methods;

  const onSubmit = (formValues: FormValues) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { id, ...restValues } = formValues;
    updateConstraintsMutation({
      variables: { schema: { fields: { ...restValues }, id } },
      refetchQueries: [FIND_AND_COUNT_MANY_CONSTRAINTS_BY_ADMIN],
      onCompleted: (data) => {
        if (data) toast.success("Successful");
      },
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    });
    navigate(PATHS.constraints);
  };

  return (
    <Box pb="24px">
      <FormProvider {...methods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Card sx={{ width: "50%" }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid container spacing={3} xs={12} item>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="mail-limit">Mail limit</InputLabel>
                    <OutlinedInput
                      id="mail-limit"
                      type="number"
                      fullWidth
                      autoComplete="mail-limit"
                      error={Boolean(errors.mailLimit)}
                      {...register("mailLimit")}
                    />
                    {Boolean(errors.mailLimit) && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-mail-limit"
                      >
                        {errors?.mailLimit?.message?.toString()}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="mail-sent">Mail sent</InputLabel>
                    <OutlinedInput
                      id="mail-sent"
                      type="number"
                      fullWidth
                      autoComplete="mail-sent"
                      error={Boolean(errors.mailSent)}
                      {...register("mailSent")}
                    />
                    {Boolean(errors.mailSent) && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-mail-sent"
                      >
                        {errors?.mailSent?.message?.toString()}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="sms-limit">Sms limit</InputLabel>
                    <OutlinedInput
                      id="sms-limit"
                      type="number"
                      fullWidth
                      autoComplete="sms-limit"
                      error={Boolean(errors.smsLimit)}
                      {...register("smsLimit")}
                    />
                    {Boolean(errors.smsLimit) && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-sms-limit"
                      >
                        {errors?.smsLimit?.message?.toString()}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="sms-sent">Sms sent</InputLabel>
                    <OutlinedInput
                      id="sms-sent"
                      type="number"
                      fullWidth
                      autoComplete="sms-sent"
                      error={Boolean(errors.smsSent)}
                      {...register("smsSent")}
                    />
                    {Boolean(errors.smsSent) && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-sms-sent"
                      >
                        {errors?.smsSent?.message?.toString()}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Button
            disabled={loading}
            type="submit"
            sx={{ marginTop: "24px" }}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Update constraints
          </Button>
        </Box>
      </FormProvider>
    </Box>
  );
}
export default UpdateConstraintsPage;
