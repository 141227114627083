import {
  Box,
  CircularProgress,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { DEFAULT_CLINICS_LIMIT, PATHS } from "src/constants";
import {
  ClinicSortFields,
  SortingOrders,
  useFindAndCountManyConstraintsByAdminLazyQuery,
} from "src/graphql/generated";
import { usePagination } from "src/hooks/usePagination";
import { useNavigate } from "react-router-dom";

function ConstraintsPage() {
  const navigate = useNavigate();

  const [getFindAndCountManyConstraintsLazyQuery, { data, loading }] =
    useFindAndCountManyConstraintsByAdminLazyQuery();

  const { offset, currentPage, pages, setCurrentPage } = usePagination({
    limit: DEFAULT_CLINICS_LIMIT,
    count: data?.findAndCountManyClinicsByAdmin?.count || 0,
  });

  useEffect(() => {
    getFindAndCountManyConstraintsLazyQuery({
      variables: {
        schema: {
          offset,
          limit: DEFAULT_CLINICS_LIMIT,
          sortOrder: SortingOrders.Descending,
          sortField: ClinicSortFields.CreatedAt,
        },
      },
      fetchPolicy: "no-cache",
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    });
  }, [currentPage]);

  const setPaginationPageHandler = (
    _: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setCurrentPage(page);
  };

  const handleOpenUpdateConstraintsPage =
    (
      id: string,
      mailLimit: number,
      mailSent: number,
      smsLimit: number,
      smsSent: number,
    ) =>
    () => {
      const searchParams = new URLSearchParams({
        id,
        mailLimit: mailLimit.toString(),
        mailSent: mailSent.toString(),
        smsLimit: smsLimit.toString(),
        smsSent: smsSent.toString(),
      });
      navigate({
        pathname: PATHS.updateConstraints,
        search: searchParams.toString(),
      });
    };

  return (
    <Box pb="24px">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">ClinicId</TableCell>
                  <TableCell align="center">Mail limit</TableCell>
                  <TableCell align="center">Mail sent</TableCell>
                  <TableCell align="center">SMS limit</TableCell>
                  <TableCell align="center">SMS sent</TableCell>
                  <TableCell align="center"> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.findAndCountManyClinicsByAdmin?.clinics.map((clinic) => (
                  <TableRow
                    key={clinic.constraints?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{clinic.name}</TableCell>
                    <TableCell align="center">
                      {clinic.constraints?.clinicId}
                    </TableCell>
                    <TableCell align="center">
                      {clinic.constraints?.mailLimit}
                    </TableCell>
                    <TableCell align="center">
                      {clinic.constraints?.mailSent}
                    </TableCell>
                    <TableCell align="center">
                      {clinic.constraints?.smsLimit}
                    </TableCell>
                    <TableCell align="center">
                      {clinic.constraints?.smsSent}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={handleOpenUpdateConstraintsPage(
                          clinic.constraints?.id as string,
                          clinic.constraints?.mailLimit as number,
                          clinic.constraints?.mailSent as number,
                          clinic.constraints?.smsLimit as number,
                          clinic.constraints?.smsSent as number,
                        )}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={4}>
            <Pagination
              count={pages.length}
              page={currentPage}
              onChange={setPaginationPageHandler}
              variant="outlined"
              color="primary"
            />
          </Box>
        </>
      )}
    </Box>
  );
}
export default ConstraintsPage;
