/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type AddPinCodeResponse = {
  __typename?: "AddPINCodeResponse";
  added: Scalars["Boolean"]["output"];
};

export type AddPinCodeSchema = {
  pinCode: Scalars["String"]["input"];
};

export type AdminLocalSignInResponse = {
  __typename?: "AdminLocalSignInResponse";
  signedIn: Scalars["Boolean"]["output"];
  twoFactorAuthenticationEnabled: Scalars["Boolean"]["output"];
};

export type AdminLocalSignInSchema = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type AggregatedDoctorEntitiesResponse = {
  __typename?: "AggregatedDoctorEntitiesResponse";
  doctors: Array<AggregatedDoctorEntityResponse>;
};

export type AggregatedDoctorEntityResponse = {
  __typename?: "AggregatedDoctorEntityResponse";
  activated: Scalars["Boolean"]["output"];
  address?: Maybe<Scalars["String"]["output"]>;
  avatarFileId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  doctorCreatedAt: Scalars["DateTime"]["output"];
  doctorId: Scalars["String"]["output"];
  doctorTypeId: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  medicalCertificateNumber: Scalars["String"]["output"];
  passportNumber?: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  promoCode?: Maybe<Scalars["String"]["output"]>;
  roles: Array<UserRoles>;
};

export type AggregatedFilledFormEntityResponse = {
  __typename?: "AggregatedFilledFormEntityResponse";
  answers: Array<AggregatedFormAnswerEntityResponse>;
  clinicId: Scalars["String"]["output"];
  fields: Array<FormFieldEntityWithRelationsResponse>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  serviceId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type AggregatedFormAnswerEntityResponse = {
  __typename?: "AggregatedFormAnswerEntityResponse";
  answers: Array<FormFieldAnswerResponse>;
  createdAt: Scalars["DateTime"]["output"];
  entityId?: Maybe<Scalars["String"]["output"]>;
  entityType?: Maybe<FormAnswerEntityTypes>;
  formId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  patient: PatientEntityResponse;
  patientId: Scalars["String"]["output"];
};

export type AggregatedScheduleAppointmentEntityWithRelationsByClientResponse = {
  __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByClientResponse";
  answer?: Maybe<FormAnswerEntityWithRelationsResponse>;
  clinic: ClinicEntityResponse;
  createdAt: Scalars["DateTime"]["output"];
  doctor: AggregatedDoctorEntityResponse;
  id: Scalars["String"]["output"];
  patient: PatientEntityResponse;
  patientId: Scalars["String"]["output"];
  room?: Maybe<ClinicRoomEntityResponse>;
  schedule: ScheduleEntityResponse;
  scheduleId: Scalars["String"]["output"];
  service?: Maybe<ServiceEntityResponse>;
  status: AppointmentStatuses;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse = {
  __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse";
  clinic: ClinicEntityResponse;
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  patient: PatientEntityResponse;
  patientId: Scalars["String"]["output"];
  room?: Maybe<ClinicRoomEntityResponse>;
  schedule: ScheduleEntityResponse;
  scheduleId: Scalars["String"]["output"];
  service?: Maybe<ServiceEntityResponse>;
  status: AppointmentStatuses;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse =
  {
    __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse";
    answer?: Maybe<FormAnswerEntityResponse>;
    client: ClientEntityResponse;
    clinic: ClinicEntityResponse;
    createdAt: Scalars["DateTime"]["output"];
    doctor: AggregatedDoctorEntityResponse;
    id: Scalars["String"]["output"];
    languageISOCode: LanguageIsoCodes;
    patient: PatientEntityResponse;
    patientId: Scalars["String"]["output"];
    schedule: ScheduleEntityResponse;
    scheduleId: Scalars["String"]["output"];
    service: ServiceEntityResponse;
    status: AppointmentStatuses;
    updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  };

export type AggregatedScheduleWaitlistEntityByClientResponse = {
  __typename?: "AggregatedScheduleWaitlistEntityByClientResponse";
  clinicId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  dateFrom: Scalars["String"]["output"];
  dateTo: Scalars["String"]["output"];
  doctor?: Maybe<AggregatedDoctorEntityResponse>;
  doctorId?: Maybe<Scalars["String"]["output"]>;
  hash: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  minuteFrom: Scalars["Int"]["output"];
  minuteTo: Scalars["Int"]["output"];
  patient: PatientEntityResponse;
  patientId: Scalars["String"]["output"];
  service: ServiceEntityResponse;
  serviceId: Scalars["String"]["output"];
  status: WaitlistStatuses;
  uncommittedPatientId?: Maybe<Scalars["String"]["output"]>;
};

export type AggregatedScheduleWaitlistEntityByPatientResponse = {
  __typename?: "AggregatedScheduleWaitlistEntityByPatientResponse";
  answer?: Maybe<FormAnswerEntityResponse>;
  client: ClientEntityResponse;
  clinic: ClinicEntityResponse;
  clinicId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  dateFrom: Scalars["String"]["output"];
  dateTo: Scalars["String"]["output"];
  doctor: AggregatedDoctorEntityResponse;
  doctorId?: Maybe<Scalars["String"]["output"]>;
  hash: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  languageISOCode: LanguageIsoCodes;
  minuteFrom: Scalars["Int"]["output"];
  minuteTo: Scalars["Int"]["output"];
  patient: PatientEntityResponse;
  patientId: Scalars["String"]["output"];
  service: ServiceEntityResponse;
  serviceId: Scalars["String"]["output"];
  status: WaitlistStatuses;
  uncommittedPatientId?: Maybe<Scalars["String"]["output"]>;
};

export type AggregatedSmsEntityResponse = {
  __typename?: "AggregatedSmsEntityResponse";
  clinic: ClinicEntityResponse;
  clinicId: Scalars["String"]["output"];
  clinicToPatient: Scalars["Boolean"]["output"];
  content: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  from: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  patient: PatientEntityResponse;
  patientId: Scalars["String"]["output"];
  to: Scalars["String"]["output"];
};

/** Enum of appointment sort fields */
export enum AppointmentSortFields {
  CreatedAt = "CreatedAt",
  ScheduleTimeFrom = "ScheduleTimeFrom",
  UpdatedAt = "UpdatedAt",
}

/** Enum of appointment statuses */
export enum AppointmentStatuses {
  Canceled = "Canceled",
  ConfirmedByApplication = "ConfirmedByApplication",
  ConfirmedByPatient = "ConfirmedByPatient",
  Missed = "Missed",
  New = "New",
  Successful = "Successful",
}

export type AttachDoctorToServiceResponse = {
  __typename?: "AttachDoctorToServiceResponse";
  attached: Scalars["Boolean"]["output"];
};

export type AttachDoctorToServiceSchema = {
  doctorId: Scalars["String"]["input"];
  serviceId: Scalars["String"]["input"];
};

export type AuthorizeWith2FaResponse = {
  __typename?: "AuthorizeWith2FAResponse";
  authorized: Scalars["Boolean"]["output"];
};

export type AuthorizeWith2FaSchema = {
  code: Scalars["String"]["input"];
};

export type AuthorizeWithPinCodeResponse = {
  __typename?: "AuthorizeWithPINCodeResponse";
  authorized: Scalars["Boolean"]["output"];
};

export type AuthorizeWithPinCodeSchema = {
  pinCode: Scalars["String"]["input"];
};

export type AuthorizeWithPasswordResponse = {
  __typename?: "AuthorizeWithPasswordResponse";
  authorized: Scalars["Boolean"]["output"];
};

export type AuthorizeWithPasswordSchema = {
  password: Scalars["String"]["input"];
};

export type AutocompleteByClientSchema = {
  query: Scalars["String"]["input"];
};

export type AutocompleteByDoctorSchema = {
  query: Scalars["String"]["input"];
};

export type AutocompleteResultByClientResponse = {
  __typename?: "AutocompleteResultByClientResponse";
  result?: Maybe<Array<SearchByClientUnion>>;
};

export type AutocompleteResultByDoctorResponse = {
  __typename?: "AutocompleteResultByDoctorResponse";
  result?: Maybe<Array<SearchByDoctorUnion>>;
};

export type BlankFormEntityResponse = {
  __typename?: "BlankFormEntityResponse";
  clinicId: Scalars["String"]["output"];
  fields: Array<FormFieldEntityWithRelationsResponse>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  serviceId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type BlankFormWithCalculatedFieldsResponse = {
  __typename?: "BlankFormWithCalculatedFieldsResponse";
  clinicId: Scalars["String"]["output"];
  countAnswers: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  serviceId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type CalculateAverageScheduleAppointmentRateByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CalculateAverageScheduleAppointmentRateResponse = {
  __typename?: "CalculateAverageScheduleAppointmentRateResponse";
  rate: Scalars["Float"]["output"];
};

export type CalculateAverageScheduleAppointmentRateSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CalculateClinicConstraintsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CancelScheduleAppointmentByClientResponse = {
  __typename?: "CancelScheduleAppointmentByClientResponse";
  canceled: Scalars["Boolean"]["output"];
};

export type CancelScheduleAppointmentByClientSchema = {
  id: Scalars["String"]["input"];
};

export type CancelScheduleAppointmentByPatientResponse = {
  __typename?: "CancelScheduleAppointmentByPatientResponse";
  canceled: Scalars["Boolean"]["output"];
};

export type CancelScheduleAppointmentByPatientSchema = {
  hash: Scalars["String"]["input"];
};

export type ChangeEmailResponse = {
  __typename?: "ChangeEmailResponse";
  changed: Scalars["Boolean"]["output"];
};

export type ChangeEmailSchema = {
  changeHash: Scalars["String"]["input"];
};

export type CheckIfCompanyExistsByUserIdResponse = {
  __typename?: "CheckIfCompanyExistsByUserIdResponse";
  exists: Scalars["Boolean"]["output"];
};

export type ClientEntityResponse = {
  __typename?: "ClientEntityResponse";
  countryId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  passportNumber?: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  promoCode?: Maybe<Scalars["String"]["output"]>;
  userId: Scalars["String"]["output"];
};

export type ClinicConstraintEntityResponse = {
  __typename?: "ClinicConstraintEntityResponse";
  clinicId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  mailLimit: Scalars["Float"]["output"];
  mailSent: Scalars["Float"]["output"];
  smsLimit: Scalars["Float"]["output"];
  smsSent: Scalars["Float"]["output"];
};

export type ClinicConstraintResponse = {
  __typename?: "ClinicConstraintResponse";
  mailLimit: Scalars["Float"]["output"];
  mailSent: Scalars["Float"]["output"];
  smsLimit: Scalars["Float"]["output"];
  smsSent: Scalars["Float"]["output"];
};

export type ClinicEntityByAdminResponse = {
  __typename?: "ClinicEntityByAdminResponse";
  address: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  code?: Maybe<Scalars["String"]["output"]>;
  companyId: Scalars["String"]["output"];
  constraints?: Maybe<ClinicConstraintEntityResponse>;
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  geographicalCoordinates: ClinicGeographicalCoordinatesResponse;
  hiddenFromPatients: Scalars["Boolean"]["output"];
  houseNumber?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  index: Scalars["String"]["output"];
  licenseNumber?: Maybe<Scalars["String"]["output"]>;
  logoFileId?: Maybe<Scalars["String"]["output"]>;
  messagingServiceSid?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
  rooms?: Maybe<Array<ClinicRoomEntityResponse>>;
  workingDays?: Maybe<Array<ClinicWorkingDayEntityResponse>>;
};

export type ClinicEntityResponse = {
  __typename?: "ClinicEntityResponse";
  address: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  code?: Maybe<Scalars["String"]["output"]>;
  companyId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  geographicalCoordinates: ClinicGeographicalCoordinatesResponse;
  hiddenFromPatients: Scalars["Boolean"]["output"];
  houseNumber?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  index: Scalars["String"]["output"];
  licenseNumber?: Maybe<Scalars["String"]["output"]>;
  logoFileId?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
  rooms?: Maybe<Array<ClinicRoomEntityResponse>>;
  workingDays?: Maybe<Array<ClinicWorkingDayEntityResponse>>;
};

export type ClinicGeographicalCoordinatesResponse = {
  __typename?: "ClinicGeographicalCoordinatesResponse";
  latitude: Scalars["Float"]["output"];
  longitude: Scalars["Float"]["output"];
};

export type ClinicRoomEntityResponse = {
  __typename?: "ClinicRoomEntityResponse";
  clinicId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

/** Enum of clinic sort fields */
export enum ClinicRoomSortFields {
  CreatedAt = "CreatedAt",
}

/** Enum of clinic sort fields */
export enum ClinicSortFields {
  CreatedAt = "CreatedAt",
  Name = "Name",
}

export type ClinicWorkingDayEntityResponse = {
  __typename?: "ClinicWorkingDayEntityResponse";
  clinicId: Scalars["String"]["output"];
  day: ClinicWorkingDays;
  fromMinute: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  toMinute: Scalars["Float"]["output"];
};

/** Enum of clinic working days */
export enum ClinicWorkingDays {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

/** Enum of clinic sort fields */
export enum ClinicWorkingDaysSortFields {
  CreatedAt = "CreatedAt",
}

export type ClinicsTemplatesResponse = {
  __typename?: "ClinicsTemplatesResponse";
  clinicId: Scalars["String"]["output"];
  event: DistributionSystemEvents;
  templateId?: Maybe<Scalars["String"]["output"]>;
};

export type CloseScheduleWaitlistByClientResponse = {
  __typename?: "CloseScheduleWaitlistByClientResponse";
  closed: Scalars["Boolean"]["output"];
};

export type CloseScheduleWaitlistByClientSchema = {
  id: Scalars["String"]["input"];
};

export type CloseScheduleWaitlistByPatientResponse = {
  __typename?: "CloseScheduleWaitlistByPatientResponse";
  closed: Scalars["Boolean"]["output"];
};

export type CloseScheduleWaitlistByPatientSchema = {
  hash: Scalars["String"]["input"];
};

export type CompanyEntityResponse = {
  __typename?: "CompanyEntityResponse";
  address?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  countryId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  dateOfRegistrationInKrs?: Maybe<Scalars["DateTime"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  nip: Scalars["String"]["output"];
  ownerId: Scalars["String"]["output"];
  postCode?: Maybe<Scalars["String"]["output"]>;
  regon?: Maybe<Scalars["String"]["output"]>;
  street?: Maybe<Scalars["String"]["output"]>;
};

export type ConfirmMailResponse = {
  __typename?: "ConfirmMailResponse";
  confirmed: Scalars["Boolean"]["output"];
};

export type ConfirmMailSchema = {
  confirmationHash: Scalars["String"]["input"];
};

export type ConfirmScheduleAppointmentByClientResponse = {
  __typename?: "ConfirmScheduleAppointmentByClientResponse";
  confirmed: Scalars["Boolean"]["output"];
};

export type ConfirmScheduleAppointmentByClientSchema = {
  id: Scalars["String"]["input"];
};

export type ConfirmScheduleAppointmentByPatientResponse = {
  __typename?: "ConfirmScheduleAppointmentByPatientResponse";
  confirmed: Scalars["Boolean"]["output"];
};

export type ConfirmScheduleAppointmentByPatientSchema = {
  hash: Scalars["String"]["input"];
};

export type ConfirmScheduleWaitlistByPatientResponse = {
  __typename?: "ConfirmScheduleWaitlistByPatientResponse";
  confirmed: Scalars["Boolean"]["output"];
};

export type ConfirmScheduleWaitlistByPatientSchema = {
  hash: Scalars["String"]["input"];
};

export type ConvertScheduleWaitlistToScheduleAppointmentByPatientSchema = {
  date: Scalars["String"]["input"];
  hash: Scalars["String"]["input"];
  minuteFrom: Scalars["Int"]["input"];
  minuteTo: Scalars["Int"]["input"];
};

export type CountAndGroupScheduleAppointmentsByDateByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<AppointmentStatuses>;
};

export type CountAndGroupScheduleAppointmentsByDateByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<AppointmentStatuses>;
};

export type CountNewPatientsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CountNewPatientsByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CountScheduleAppointmentsByStatusesByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  dateFrom?: InputMaybe<Scalars["String"]["input"]>;
  dateTo?: InputMaybe<Scalars["String"]["input"]>;
  doctorId?: InputMaybe<Scalars["String"]["input"]>;
  minuteFrom?: InputMaybe<Scalars["Int"]["input"]>;
  minuteTo?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CountScheduleAppointmentsByStatusesByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  dateFrom?: InputMaybe<Scalars["String"]["input"]>;
  dateTo?: InputMaybe<Scalars["String"]["input"]>;
  minuteFrom?: InputMaybe<Scalars["Int"]["input"]>;
  minuteTo?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CountScheduleAppointmentsCountByClientSchema = {
  status?: InputMaybe<AppointmentStatuses>;
};

export type CountedAggregatedDoctorEntitiesResponse = {
  __typename?: "CountedAggregatedDoctorEntitiesResponse";
  count: Scalars["Int"]["output"];
  doctors: Array<AggregatedDoctorEntityResponse>;
};

export type CountedAggregatedFilledFormsResponse = {
  __typename?: "CountedAggregatedFilledFormsResponse";
  count: Scalars["Int"]["output"];
  forms: Array<AggregatedFilledFormEntityResponse>;
};

export type CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse =
  {
    __typename?: "CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse";
    appointments: Array<AggregatedScheduleAppointmentEntityWithRelationsByClientResponse>;
    count: Scalars["Int"]["output"];
  };

export type CountedAggregatedScheduleAppointmentEntitiesWithRelationsByDoctorResponse =
  {
    __typename?: "CountedAggregatedScheduleAppointmentEntitiesWithRelationsByDoctorResponse";
    appointments: Array<AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse>;
    count: Scalars["Int"]["output"];
  };

export type CountedAggregatedScheduleWaitlistEntitiesByClientResponse = {
  __typename?: "CountedAggregatedScheduleWaitlistEntitiesByClientResponse";
  count: Scalars["Int"]["output"];
  waitlists: Array<AggregatedScheduleWaitlistEntityByClientResponse>;
};

export type CountedAggregatedSmsEntitiesResponse = {
  __typename?: "CountedAggregatedSmsEntitiesResponse";
  count: Scalars["Int"]["output"];
  sms: Array<AggregatedSmsEntityResponse>;
};

export type CountedBlankFormEntitiesResponse = {
  __typename?: "CountedBlankFormEntitiesResponse";
  count: Scalars["Int"]["output"];
  forms: Array<BlankFormWithCalculatedFieldsResponse>;
};

export type CountedClinicsByAdminResponse = {
  __typename?: "CountedClinicsByAdminResponse";
  clinics: Array<ClinicEntityByAdminResponse>;
  count: Scalars["Float"]["output"];
};

export type CountedClinicsResponse = {
  __typename?: "CountedClinicsResponse";
  clinics: Array<ClinicEntityResponse>;
  count: Scalars["Float"]["output"];
};

export type CountedPatientsResponse = {
  __typename?: "CountedPatientsResponse";
  count: Scalars["Float"]["output"];
  patients: Array<PatientEntityResponse>;
};

export type CountedSmsEntitiesResponse = {
  __typename?: "CountedSmsEntitiesResponse";
  count: Scalars["Int"]["output"];
  sms: Array<SmsEntityResponse>;
};

export type CountedTemplatesResponse = {
  __typename?: "CountedTemplatesResponse";
  count: Scalars["Int"]["output"];
  templates: Array<TemplateEntityResponse>;
};

export type CountryEntityResponse = {
  __typename?: "CountryEntityResponse";
  id: Scalars["String"]["output"];
  isoAlpha2Code: Scalars["String"]["output"];
  isoAlpha3Code: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type CreateClinicGeographicalCoordinatesSchema = {
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
};

export type CreateClinicRoomWithClinicIdSchema = {
  clinicId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type CreateClinicSchema = {
  address: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  geographicalCoordinates: CreateClinicGeographicalCoordinatesSchema;
  hiddenFromPatients: Scalars["Boolean"]["input"];
  houseNumber?: InputMaybe<Scalars["String"]["input"]>;
  index: Scalars["String"]["input"];
  licenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  logoFileId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
};

export type CreateClinicWorkingDayWithClinicIdSchema = {
  clinicId: Scalars["String"]["input"];
  day: ClinicWorkingDays;
  fromMinute: Scalars["Int"]["input"];
  toMinute: Scalars["Int"]["input"];
};

export type CreateCompanySchema = {
  address: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  nip: Scalars["String"]["input"];
};

export type CreateDoctorInvitationSchema = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  clinicId: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  doctorTypeId: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  languageISOCode: LanguageIsoCodes;
  lastName: Scalars["String"]["input"];
  medicalCertificateNumber: Scalars["String"]["input"];
  passportNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateFormFieldAnswerSchema = {
  fieldId: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateFormFieldSchema = {
  hiddenFromExistingPatients: Scalars["Boolean"]["input"];
  order: Scalars["Float"]["input"];
  required: Scalars["Boolean"]["input"];
  translations: Array<CreateFormFieldTranslationSchema>;
  type: FormFieldTypes;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateFormFieldTranslationSchema = {
  languageISOCode: LanguageIsoCodes;
  name: Scalars["String"]["input"];
  placeholder: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateFormSchema = {
  clinicId: Scalars["String"]["input"];
  fields: Array<CreateFormFieldSchema>;
  name: Scalars["String"]["input"];
  serviceId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateMailTemplateSchema = {
  delayInHours: Scalars["Int"]["input"];
  languageISOCode: LanguageIsoCodes;
  subject: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
};

export type CreatePatientSchema = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  emailDistribution: Scalars["Boolean"]["input"];
  firstName: Scalars["String"]["input"];
  gender?: InputMaybe<PatientGenders>;
  languageISOCode: LanguageIsoCodes;
  lastName: Scalars["String"]["input"];
  passportNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
  postCode?: InputMaybe<Scalars["String"]["input"]>;
  smsDistribution: Scalars["Boolean"]["input"];
};

export type CreateScheduleAppointmentByClientSchema = {
  clinicId: Scalars["String"]["input"];
  date: Scalars["String"]["input"];
  doctorId: Scalars["String"]["input"];
  minuteFrom: Scalars["Int"]["input"];
  minuteTo: Scalars["Int"]["input"];
  patientId: Scalars["String"]["input"];
  roomId?: InputMaybe<Scalars["String"]["input"]>;
  serviceId: Scalars["String"]["input"];
};

export type CreateScheduleAppointmentByDoctorSchema = {
  clinicId: Scalars["String"]["input"];
  date: Scalars["String"]["input"];
  minuteFrom: Scalars["Int"]["input"];
  minuteTo: Scalars["Int"]["input"];
  patientId: Scalars["String"]["input"];
  roomId?: InputMaybe<Scalars["String"]["input"]>;
  serviceId: Scalars["String"]["input"];
};

export type CreateScheduleAppointmentByPatientResponse = {
  __typename?: "CreateScheduleAppointmentByPatientResponse";
  hash: Scalars["String"]["output"];
};

export type CreateScheduleAppointmentByPatientSchema = {
  clinicId: Scalars["String"]["input"];
  date: Scalars["String"]["input"];
  doctorId: Scalars["String"]["input"];
  formAnswer?: InputMaybe<CreateScheduleAppointmentFormAnswerByPatientSchema>;
  languageISOCode: LanguageIsoCodes;
  minuteFrom: Scalars["Int"]["input"];
  minuteTo: Scalars["Int"]["input"];
  patient: CreateScheduleAppointmentPatientByPatientSchema;
  roomId?: InputMaybe<Scalars["String"]["input"]>;
  serviceId: Scalars["String"]["input"];
};

export type CreateScheduleAppointmentFormAnswerByPatientSchema = {
  answers: Array<CreateFormFieldAnswerSchema>;
  formId: Scalars["String"]["input"];
};

export type CreateScheduleAppointmentPatientByPatientSchema = {
  address: Scalars["String"]["input"];
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  gender: PatientGenders;
  lastName: Scalars["String"]["input"];
  passportNumber: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  postCode: Scalars["String"]["input"];
};

export type CreateScheduleSchema = {
  clinicId: Scalars["String"]["input"];
  dateFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  dateTo?: InputMaybe<Scalars["DateTime"]["input"]>;
  daysOfWeek?: InputMaybe<Array<DaysOfWeek>>;
  doctorId: Scalars["String"]["input"];
  eventType: EventTypes;
  layoutType: LayoutTypes;
  minuteFrom: Scalars["Int"]["input"];
  minuteTo: Scalars["Int"]["input"];
  roomId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreateScheduleWaitlistByClientSchema = {
  clinicId: Scalars["String"]["input"];
  dateFrom: Scalars["String"]["input"];
  dateTo: Scalars["String"]["input"];
  doctorId: Scalars["String"]["input"];
  minuteFrom: Scalars["Int"]["input"];
  minuteTo: Scalars["Int"]["input"];
  patientId: Scalars["String"]["input"];
  serviceId: Scalars["String"]["input"];
};

export type CreateScheduleWaitlistByPatientResponse = {
  __typename?: "CreateScheduleWaitlistByPatientResponse";
  hash: Scalars["String"]["output"];
};

export type CreateScheduleWaitlistByPatientSchema = {
  clinicId: Scalars["String"]["input"];
  dateFrom: Scalars["String"]["input"];
  dateTo: Scalars["String"]["input"];
  doctorId: Scalars["String"]["input"];
  formAnswer?: InputMaybe<CreateScheduleWaitlistFormAnswerByPatientSchema>;
  languageISOCode: LanguageIsoCodes;
  minuteFrom: Scalars["Int"]["input"];
  minuteTo: Scalars["Int"]["input"];
  patient: CreateScheduleWaitlistPatientByPatientSchema;
  roomId?: InputMaybe<Scalars["String"]["input"]>;
  serviceId: Scalars["String"]["input"];
};

export type CreateScheduleWaitlistFormAnswerByPatientSchema = {
  answers: Array<CreateFormFieldAnswerSchema>;
  formId: Scalars["String"]["input"];
};

export type CreateScheduleWaitlistPatientByPatientSchema = {
  address: Scalars["String"]["input"];
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  gender: PatientGenders;
  lastName: Scalars["String"]["input"];
  passportNumber: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  postCode: Scalars["String"]["input"];
};

export type CreateServiceCategorySchema = {
  clinicId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type CreateServiceSchema = {
  categoryId: Scalars["String"]["input"];
  currencyCode?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  durationInMinutes: Scalars["Int"]["input"];
  hiddenFromPatients: Scalars["Boolean"]["input"];
  icd9Code?: InputMaybe<Scalars["String"]["input"]>;
  icd10Code?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  price?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CreateSmsTemplateSchema = {
  delayInHours: Scalars["Int"]["input"];
  languageISOCode: LanguageIsoCodes;
  text: Scalars["String"]["input"];
};

export type CreateSurveySchema = {
  clinicId: Scalars["String"]["input"];
  finalMessage: Scalars["String"]["input"];
  followUpResponse: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type CreateTemplateSchema = {
  actions: Array<DistributionActions>;
  clinicId: Scalars["String"]["input"];
  event: DistributionSystemEvents;
  mailTemplates: Array<CreateMailTemplateSchema>;
  name: Scalars["String"]["input"];
  smsTemplates: Array<CreateSmsTemplateSchema>;
};

export type CurrencyEntitiesResponse = {
  __typename?: "CurrencyEntitiesResponse";
  currencies: Array<CurrencyEntityResponse>;
};

export type CurrencyEntityResponse = {
  __typename?: "CurrencyEntityResponse";
  code: Scalars["String"]["output"];
};

/** Enum of days of week */
export enum DaysOfWeek {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export type DeleteClinicByIdSchema = {
  id: Scalars["String"]["input"];
};

export type DeleteClinicLogoFileIdResponse = {
  __typename?: "DeleteClinicLogoFileIdResponse";
  deleted: Scalars["Boolean"]["output"];
};

export type DeleteClinicLogoFileIdSchema = {
  id: Scalars["String"]["input"];
};

export type DeleteClinicResponse = {
  __typename?: "DeleteClinicResponse";
  deleted: Scalars["Boolean"]["output"];
};

export type DeleteClinicRoomByIdSchema = {
  id: Scalars["String"]["input"];
};

export type DeleteClinicRoomResponse = {
  __typename?: "DeleteClinicRoomResponse";
  deleted: Scalars["Boolean"]["output"];
};

export type DeleteClinicWorkingDayByIdSchema = {
  id: Scalars["String"]["input"];
};

export type DeleteClinicWorkingDayResponse = {
  __typename?: "DeleteClinicWorkingDayResponse";
  deleted: Scalars["Boolean"]["output"];
};

export type DeleteDoctorByIdSchema = {
  doctorId: Scalars["String"]["input"];
};

export type DeleteDoctorResponse = {
  __typename?: "DeleteDoctorResponse";
  deleted: Scalars["Boolean"]["output"];
};

export type DeletePatientByIdSchema = {
  id: Scalars["String"]["input"];
};

export type DeletePatientResponse = {
  __typename?: "DeletePatientResponse";
  deleted: Scalars["Boolean"]["output"];
};

export type DeleteScheduleByIdByClientResponse = {
  __typename?: "DeleteScheduleByIdByClientResponse";
  deleted: Scalars["Boolean"]["output"];
};

export type DeleteScheduleByIdByClientSchema = {
  id: Scalars["String"]["input"];
};

export type DeleteServiceByIdSchema = {
  id: Scalars["String"]["input"];
};

export type DeleteServiceCategoryByIdResponse = {
  __typename?: "DeleteServiceCategoryByIdResponse";
  deleted: Scalars["Boolean"]["output"];
};

export type DeleteServiceCategoryByIdSchema = {
  id: Scalars["String"]["input"];
};

export type DeleteServiceResponse = {
  __typename?: "DeleteServiceResponse";
  deleted: Scalars["Boolean"]["output"];
};

export type DeleteUserAvatarFileIdResponse = {
  __typename?: "DeleteUserAvatarFileIdResponse";
  deleted: Scalars["Boolean"]["output"];
};

/** Distribution actions */
export enum DistributionActions {
  SendMail = "SendMail",
  SendSms = "SendSms",
  Timer = "Timer",
}

/** Distribution system events */
export enum DistributionSystemEvents {
  AppointmentCanceledByClient = "AppointmentCanceledByClient",
  AppointmentCanceledByPatient = "AppointmentCanceledByPatient",
  AppointmentConfirmedByClient = "AppointmentConfirmedByClient",
  AppointmentConfirmedByPatient = "AppointmentConfirmedByPatient",
  AppointmentCreatedByClient = "AppointmentCreatedByClient",
  AppointmentCreatedByPatient = "AppointmentCreatedByPatient",
  AppointmentMissed = "AppointmentMissed",
  AppointmentRemindedBefore48Hours = "AppointmentRemindedBefore48Hours",
  AppointmentRescheduledByClient = "AppointmentRescheduledByClient",
  AppointmentRescheduledByPatient = "AppointmentRescheduledByPatient",
  AppointmentSuccessful1Hour = "AppointmentSuccessful1Hour",
  AppointmentSuccessful6Months = "AppointmentSuccessful6Months",
  WaitlistConfirmedByPatient = "WaitlistConfirmedByPatient",
  WaitlistCreatedByClient = "WaitlistCreatedByClient",
  WaitlistCreatedByPatient = "WaitlistCreatedByPatient",
  WaitlistTimeslotAvailable = "WaitlistTimeslotAvailable",
}

export type DoctorEntityResponse = {
  __typename?: "DoctorEntityResponse";
  address?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  doctorTypeId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  medicalCertificateNumber: Scalars["String"]["output"];
  passportNumber?: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  promoCode?: Maybe<Scalars["String"]["output"]>;
  userId: Scalars["String"]["output"];
};

/** Enum of clinic sort fields */
export enum DoctorSortFields {
  CreatedAt = "CreatedAt",
}

export type DoctorTypeEntitiesResponse = {
  __typename?: "DoctorTypeEntitiesResponse";
  doctorTypes: Array<DoctorTypeEntityResponse>;
};

export type DoctorTypeEntityResponse = {
  __typename?: "DoctorTypeEntityResponse";
  defaultLanguageName: Scalars["String"]["output"];
  doctorTypeTranslations: Array<DoctorTypeTranslationEntityResponse>;
  id: Scalars["String"]["output"];
};

export type DoctorTypeTranslationEntityResponse = {
  __typename?: "DoctorTypeTranslationEntityResponse";
  doctorTypeId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  languageISOCode: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type EnableTwoFactorAuthenticationResponse = {
  __typename?: "EnableTwoFactorAuthenticationResponse";
  enabled: Scalars["Boolean"]["output"];
};

export type EnableTwoFactorAuthenticationSchema = {
  code: Scalars["String"]["input"];
};

/** Enum of event types */
export enum EventTypes {
  Appointment = "Appointment",
  Vacation = "Vacation",
  Working = "Working",
}

export type FileEntityResponse = {
  __typename?: "FileEntityResponse";
  createdAt: Scalars["DateTime"]["output"];
  extension: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  mimetype: Scalars["String"]["output"];
  originalName: Scalars["String"]["output"];
  resourceKey: Scalars["String"]["output"];
  /** File size in bytes */
  size: Scalars["Int"]["output"];
  url: Scalars["String"]["output"];
  usageStatus: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

/** The usage status of the file */
export enum FileUsageStatuses {
  Failed = "Failed",
  Fulfilled = "Fulfilled",
  Pending = "Pending",
}

export type FindAllCountriesResponse = {
  __typename?: "FindAllCountriesResponse";
  countries: Array<CountryEntityResponse>;
};

export type FindAndCountManyClinics = {
  limit?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Float"]["input"]>;
  sortField?: InputMaybe<ClinicSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyClinicsByClientIdSchema = {
  clientId: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FindAndCountManyDoctorsByClinicIdSchema = {
  clinicId: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<DoctorSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyDoctorsByUserIdSchema = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<DoctorSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyFormsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  patientId?: InputMaybe<Scalars["String"]["input"]>;
};

export type FindAndCountManyFormsByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  patientId?: InputMaybe<Scalars["String"]["input"]>;
};

export type FindAndCountManyLastSmsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FindAndCountManyPatientsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sortField?: InputMaybe<PatientSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyPatientsByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Float"]["input"]>;
  offset?: InputMaybe<Scalars["Float"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  sortField?: InputMaybe<PatientSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyScheduleAppointmentsWithRelationsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  dateFrom?: InputMaybe<Scalars["String"]["input"]>;
  dateTo?: InputMaybe<Scalars["String"]["input"]>;
  doctorIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  minuteFrom?: InputMaybe<Scalars["Int"]["input"]>;
  minuteTo?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  patientId?: InputMaybe<Scalars["String"]["input"]>;
  roomIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sortField?: InputMaybe<AppointmentSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
  status?: InputMaybe<AppointmentStatuses>;
};

export type FindAndCountManyScheduleAppointmentsWithRelationsByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  dateFrom?: InputMaybe<Scalars["String"]["input"]>;
  dateTo?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  minuteFrom?: InputMaybe<Scalars["Int"]["input"]>;
  minuteTo?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  patientId?: InputMaybe<Scalars["String"]["input"]>;
  roomIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sortField?: InputMaybe<AppointmentSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
  status?: InputMaybe<AppointmentStatuses>;
};

export type FindAndCountManyScheduleWaitlistsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  doctorId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  patientId?: InputMaybe<Scalars["String"]["input"]>;
  serviceId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<WaitlistStatuses>;
};

export type FindAndCountManySmsByClientSchema = {
  clinicId: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  patientId: Scalars["String"]["input"];
  sortField?: InputMaybe<SmsSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyTemplatesByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  onlyCurrent: Scalars["Boolean"]["input"];
  sortField: TemplateSortFields;
  sortOrder: SortingOrders;
};

export type FindBlankFormByServiceIdSchema = {
  serviceId: Scalars["String"]["input"];
};

export type FindClinicByIdSchema = {
  id: Scalars["String"]["input"];
};

export type FindClinicRoomByIdSchema = {
  id: Scalars["String"]["input"];
};

export type FindDefaultMailTemplateSchema = {
  event: DistributionSystemEvents;
  languageISOCode: LanguageIsoCodes;
};

export type FindDefaultSmsTemplateSchema = {
  event: DistributionSystemEvents;
  languageISOCode: LanguageIsoCodes;
};

export type FindDoctorByIdSchema = {
  doctorId: Scalars["String"]["input"];
};

export type FindManyAggregatedDoctorsByServiceIdSchema = {
  serviceId: Scalars["String"]["input"];
};

export type FindManyAvailableSlotsByDateRangeSchema = {
  dateFrom: Scalars["String"]["input"];
  dateTo: Scalars["String"]["input"];
  doctorId: Scalars["String"]["input"];
  serviceId: Scalars["String"]["input"];
};

export type FindManyAvailableSlotsResponse = {
  __typename?: "FindManyAvailableSlotsResponse";
  availableSlots: Array<SlotsGroupedByDateResponse>;
};

export type FindManyAvailableSlotsSchema = {
  doctorId: Scalars["String"]["input"];
  month: Scalars["Int"]["input"];
  serviceId: Scalars["String"]["input"];
  year: Scalars["Int"]["input"];
};

export type FindManyClinicWorkingDaysByClinicIdResponse = {
  __typename?: "FindManyClinicWorkingDaysByClinicIdResponse";
  workingDays: Array<ClinicWorkingDayEntityResponse>;
};

export type FindManyClinicWorkingDaysByClinicIdSchema = {
  clinicId: Scalars["String"]["input"];
  sortField?: InputMaybe<ClinicWorkingDaysSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindManyDoctorClinicsByDoctorIdResponse = {
  __typename?: "FindManyDoctorClinicsByDoctorIdResponse";
  clinics: Array<ClinicEntityResponse>;
};

export type FindManyDoctorClinicsByDoctorIdSchema = {
  doctorId: Scalars["String"]["input"];
};

export type FindManyDoctorTypesByLanguageIsoCodeSchema = {
  languageISOCode: LanguageIsoCodes;
};

export type FindManyFilesByIdsResponse = {
  __typename?: "FindManyFilesByIdsResponse";
  files: Array<FileEntityResponse>;
};

export type FindManyFilesByIdsSchema = {
  ids: Array<Scalars["String"]["input"]>;
};

export type FindManyRoomsByClinicIdResponse = {
  __typename?: "FindManyRoomsByClinicIdResponse";
  rooms: Array<ClinicRoomEntityResponse>;
};

export type FindManyRoomsByClinicIdSchema = {
  clinicId: Scalars["String"]["input"];
  sortField?: InputMaybe<ClinicRoomSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindManyScheduleAppointmentUpdatesByIdByClientSchema = {
  appointmentId: Scalars["String"]["input"];
};

export type FindManySchedulesByClientSchema = {
  clinicId: Scalars["String"]["input"];
  doctorId: Scalars["String"]["input"];
};

export type FindManySchedulesByDoctorSchema = {
  clinicId: Scalars["String"]["input"];
};

export type FindManyServiceCategoriesByClinicIdResponse = {
  __typename?: "FindManyServiceCategoriesByClinicIdResponse";
  categories: Array<ServiceCategoryEntityResponse>;
};

export type FindManyServiceCategoriesByClinicIdSchema = {
  clinicId: Scalars["String"]["input"];
  sortField?: InputMaybe<ServiceSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindManyServicesSchema = {
  clinicId: Scalars["String"]["input"];
  sortField?: InputMaybe<ServiceSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindManyTemplateVariablesByClientResponse = {
  __typename?: "FindManyTemplateVariablesByClientResponse";
  variables: Array<Scalars["String"]["output"]>;
};

export type FindManyTemplateVariablesByClientSchema = {
  event: DistributionSystemEvents;
};

export type FindPatientByIdSchema = {
  id: Scalars["String"]["input"];
};

export type FindScheduleAppointmentWithRelationsByHashByPatientSchema = {
  hash: Scalars["String"]["input"];
};

export type FindSentTemplateCountByEventResponse = {
  __typename?: "FindSentTemplateCountByEventResponse";
  sent: Scalars["Int"]["output"];
};

export type FindSentTemplateCountByEventSchema = {
  clinicId?: InputMaybe<Scalars["String"]["input"]>;
  event: DistributionSystemEvents;
};

export type FindServiceByIdSchema = {
  id: Scalars["String"]["input"];
};

export type FindServiceCategoryByIdSchema = {
  id: Scalars["String"]["input"];
};

export type FindSystemEventsResponse = {
  __typename?: "FindSystemEventsResponse";
  events: Array<Scalars["String"]["output"]>;
};

export type FindTemplateByClientSchema = {
  clinicId: Scalars["String"]["input"];
  event: DistributionSystemEvents;
};

export type FormAnswerEntityResponse = {
  __typename?: "FormAnswerEntityResponse";
  answers: Array<FormFieldAnswerResponse>;
  createdAt: Scalars["DateTime"]["output"];
  entityId?: Maybe<Scalars["String"]["output"]>;
  entityType?: Maybe<FormAnswerEntityTypes>;
  formId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  patientId: Scalars["String"]["output"];
};

/** Enum of entity types in form answer */
export enum FormAnswerEntityTypes {
  Appointment = "Appointment",
  Waitlist = "Waitlist",
}

export type FormAnswerEntityWithRelationsResponse = {
  __typename?: "FormAnswerEntityWithRelationsResponse";
  answers: Array<FormFieldAnswerResponse>;
  createdAt: Scalars["DateTime"]["output"];
  entityId?: Maybe<Scalars["String"]["output"]>;
  entityType?: Maybe<FormAnswerEntityTypes>;
  form: FormEntityWithRelationsResponse;
  formId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  patientId: Scalars["String"]["output"];
};

export type FormEntityResponse = {
  __typename?: "FormEntityResponse";
  clinicId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  serviceId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type FormEntityWithRelationsResponse = {
  __typename?: "FormEntityWithRelationsResponse";
  clinicId: Scalars["String"]["output"];
  fields: Array<FormFieldEntityWithRelationsResponse>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  serviceId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type FormFieldAnswerResponse = {
  __typename?: "FormFieldAnswerResponse";
  fieldName: Scalars["String"]["output"];
  fieldType: FormFieldTypes;
  fieldValue?: Maybe<Scalars["String"]["output"]>;
  order: Scalars["Int"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type FormFieldEntityWithRelationsResponse = {
  __typename?: "FormFieldEntityWithRelationsResponse";
  formId: Scalars["String"]["output"];
  hiddenFromExistingPatients: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  order: Scalars["Float"]["output"];
  required: Scalars["Boolean"]["output"];
  translations: Array<FormFieldTranslationEntityResponse>;
  type: FormFieldTypes;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type FormFieldTranslationEntityResponse = {
  __typename?: "FormFieldTranslationEntityResponse";
  fieldId: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  languageISOCode: LanguageIsoCodes;
  name: Scalars["String"]["output"];
  placeholder: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

/** Enum of form field types */
export enum FormFieldTypes {
  Checkbox = "Checkbox",
  Date = "Date",
  Email = "Email",
  Number = "Number",
  Phone = "Phone",
  Text = "Text",
  TextArea = "TextArea",
  Time = "Time",
}

export type GeneratedTwoFactorAuthenticationResponse = {
  __typename?: "GeneratedTwoFactorAuthenticationResponse";
  qrCodeSVG: Scalars["String"]["output"];
  secret: Scalars["String"]["output"];
};

/** Enum of available language ISO codes */
export enum LanguageIsoCodes {
  En = "EN",
  Pl = "PL",
}

/** Enum of layout types */
export enum LayoutTypes {
  Custom = "Custom",
  DayOfWeek = "DayOfWeek",
}

export type LocalSignInByAdminResponse = {
  __typename?: "LocalSignInByAdminResponse";
  signedIn: Scalars["Boolean"]["output"];
};

export type LocalSignInByAdminSchema = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type LocalSignInResponse = {
  __typename?: "LocalSignInResponse";
  signedIn: Scalars["Boolean"]["output"];
  twoFactorAuthenticationEnabled: Scalars["Boolean"]["output"];
};

export type LocalSignInSchema = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type LocalSignUpResponse = {
  __typename?: "LocalSignUpResponse";
  signedUp: Scalars["Boolean"]["output"];
};

export type LocalSignUpSchema = {
  countryId: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  languageISOCode: LanguageIsoCodes;
  lastName: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type MailTemplateEntityResponse = {
  __typename?: "MailTemplateEntityResponse";
  createdAt: Scalars["DateTime"]["output"];
  delayInHours: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  languageISOCode: Scalars["String"]["output"];
  subject: Scalars["String"]["output"];
  templateId: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type MailTemplateResponse = {
  __typename?: "MailTemplateResponse";
  subject: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  addPINCode: AddPinCodeResponse;
  adminLocalSignIn: AdminLocalSignInResponse;
  attachDoctorToService: AttachDoctorToServiceResponse;
  authorizeWith2FA: AuthorizeWith2FaResponse;
  authorizeWithPINCode: AuthorizeWithPinCodeResponse;
  authorizeWithPassword: AuthorizeWithPasswordResponse;
  cancelScheduleAppointmentByClient: CancelScheduleAppointmentByClientResponse;
  cancelScheduleAppointmentByPatient: CancelScheduleAppointmentByPatientResponse;
  changeEmail: ChangeEmailResponse;
  closeScheduleWaitlistByClient: CloseScheduleWaitlistByClientResponse;
  closeScheduleWaitlistByPatient: CloseScheduleWaitlistByPatientResponse;
  confirmMail: ConfirmMailResponse;
  confirmScheduleAppointmentByClient: ConfirmScheduleAppointmentByClientResponse;
  confirmScheduleAppointmentByPatient: ConfirmScheduleAppointmentByPatientResponse;
  confirmScheduleWaitlistByPatient: ConfirmScheduleWaitlistByPatientResponse;
  convertScheduleWaitlistToScheduleAppointmentByPatient: ScheduleAppointmentEntityResponse;
  createClinic: ClinicEntityResponse;
  createClinicRoom: ClinicRoomEntityResponse;
  createClinicWorkingDay: ClinicWorkingDayEntityResponse;
  createCompany: CompanyEntityResponse;
  createDoctorInvitation: DoctorEntityResponse;
  createFormByClient: FormEntityResponse;
  createPatient: PatientEntityResponse;
  createSchedule: ScheduleEntityResponse;
  createScheduleAppointmentByClient: ScheduleAppointmentEntityResponse;
  createScheduleAppointmentByDoctor: ScheduleAppointmentEntityResponse;
  createScheduleAppointmentByPatient: CreateScheduleAppointmentByPatientResponse;
  createScheduleWaitlistByClient: ScheduleWaitlistEntityResponse;
  createScheduleWaitlistByPatient: CreateScheduleWaitlistByPatientResponse;
  createService: ServiceEntityResponse;
  createServiceCategory: ServiceCategoryEntityResponse;
  createSurvey: SurveyEntityResponse;
  createTemplate: TemplateEntityResponse;
  deleteClinicById: DeleteClinicResponse;
  deleteClinicLogoFileId: DeleteClinicLogoFileIdResponse;
  deleteClinicRoomById: DeleteClinicRoomResponse;
  deleteClinicWorkingDayById: DeleteClinicWorkingDayResponse;
  deleteDoctorById: DeleteDoctorResponse;
  deletePatientById: DeletePatientResponse;
  deleteScheduleByIdByClient: DeleteScheduleByIdByClientResponse;
  deleteServiceById: DeleteServiceResponse;
  deleteServiceCategoryById: DeleteServiceCategoryByIdResponse;
  deleteUserAvatarFileId: DeleteUserAvatarFileIdResponse;
  enable2FA: EnableTwoFactorAuthenticationResponse;
  generate2FA: GeneratedTwoFactorAuthenticationResponse;
  localSignIn: LocalSignInResponse;
  localSignInByAdmin: LocalSignInByAdminResponse;
  localSignUp: LocalSignUpResponse;
  rateScheduleAppointmentByPatient: RateScheduleAppointmentByPatientResponse;
  requestEmailChange: RequestEmailChangeResponse;
  requestPasswordRestorage: RequestPasswordRestorageResponse;
  rescheduleAppointmentByClient: RescheduleScheduleAppointmentByClientResponse;
  rescheduleScheduleAppointmentByPatient: RescheduleScheduleAppointmentByPatientResponse;
  rescheduleWaitlistByClient: RescheduleScheduleWaitlistByClientResponse;
  rescheduleWaitlistByPatient: RescheduleScheduleWaitlistByPatientResponse;
  restorePassword: RestorePasswordResponse;
  sendRawSmsByClient: SendRawSmsResponse;
  unattachDoctorService: UnattachServiceDoctorResponse;
  updateClinicById: ClinicEntityResponse;
  updateClinicConstraints: UpdateTemplateByClientResponse;
  updateClinicLogoFileId: UpdateClinicLogoFileIdResponse;
  updateClinicMessagingServiceSidByAdmin: UpdateClinicLogoFileIdResponse;
  updateClinicRoomById: UpdateClinicRoomByIdResponse;
  updateClinicWorkingDaysByClinicId: UpdateClinicWorkingDaysByClinicIdResponse;
  updateCurrentClinicTemplate: UpdateTemplateByClientResponse;
  updateDoctorAvatarFileIdByClient: UpdateDoctorResponse;
  updateDoctorByIdByClient: UpdateDoctorResponse;
  updateDoctorByUserIdByDoctor: UpdateDoctorResponse;
  updateDoctorClinicsByClient: UpdateDoctorClinicsResponse;
  updateFormByClient: UpdateFormResponse;
  updateMailTemplateByClient: UpdateTemplateByClientResponse;
  updatePatientById: UpdatePatientByIdResponse;
  updateScheduleAppointmentMissedStatusByClient: UpdateScheduleAppointmentMissedStatusByClientResponse;
  updateScheduleAppointmentStatusByIdByClient: UpdateScheduleAppointmentStatusByIdByClientResponse;
  updateScheduleByIdByClient: UpdateScheduleByIdByClientResponse;
  updateServiceById: UpdateServiceByIdResponse;
  updateServiceCategoryById: UpdateServiceCategoryByIdResponse;
  updateSmsTemplateByClient: UpdateTemplateByClientResponse;
  updateTemplateActionsByClient: UpdateTemplateActionsByClientResponse;
  updateTemplateByClient: UpdateTemplateByClientResponse;
  updateUserAvatarFileId: UpdateUserAvatarFileIdResponse;
  uploadFile: FileEntityResponse;
};

export type MutationAddPinCodeArgs = {
  schema: AddPinCodeSchema;
};

export type MutationAdminLocalSignInArgs = {
  schema: AdminLocalSignInSchema;
};

export type MutationAttachDoctorToServiceArgs = {
  schema: AttachDoctorToServiceSchema;
};

export type MutationAuthorizeWith2FaArgs = {
  schema: AuthorizeWith2FaSchema;
};

export type MutationAuthorizeWithPinCodeArgs = {
  schema: AuthorizeWithPinCodeSchema;
};

export type MutationAuthorizeWithPasswordArgs = {
  schema: AuthorizeWithPasswordSchema;
};

export type MutationCancelScheduleAppointmentByClientArgs = {
  schema: CancelScheduleAppointmentByClientSchema;
};

export type MutationCancelScheduleAppointmentByPatientArgs = {
  schema: CancelScheduleAppointmentByPatientSchema;
};

export type MutationChangeEmailArgs = {
  schema: ChangeEmailSchema;
};

export type MutationCloseScheduleWaitlistByClientArgs = {
  schema: CloseScheduleWaitlistByClientSchema;
};

export type MutationCloseScheduleWaitlistByPatientArgs = {
  schema: CloseScheduleWaitlistByPatientSchema;
};

export type MutationConfirmMailArgs = {
  schema: ConfirmMailSchema;
};

export type MutationConfirmScheduleAppointmentByClientArgs = {
  schema: ConfirmScheduleAppointmentByClientSchema;
};

export type MutationConfirmScheduleAppointmentByPatientArgs = {
  schema: ConfirmScheduleAppointmentByPatientSchema;
};

export type MutationConfirmScheduleWaitlistByPatientArgs = {
  schema: ConfirmScheduleWaitlistByPatientSchema;
};

export type MutationConvertScheduleWaitlistToScheduleAppointmentByPatientArgs =
  {
    schema: ConvertScheduleWaitlistToScheduleAppointmentByPatientSchema;
  };

export type MutationCreateClinicArgs = {
  schema: CreateClinicSchema;
};

export type MutationCreateClinicRoomArgs = {
  schema: CreateClinicRoomWithClinicIdSchema;
};

export type MutationCreateClinicWorkingDayArgs = {
  schema: CreateClinicWorkingDayWithClinicIdSchema;
};

export type MutationCreateCompanyArgs = {
  schema: CreateCompanySchema;
};

export type MutationCreateDoctorInvitationArgs = {
  schema: CreateDoctorInvitationSchema;
};

export type MutationCreateFormByClientArgs = {
  schema: CreateFormSchema;
};

export type MutationCreatePatientArgs = {
  schema: CreatePatientSchema;
};

export type MutationCreateScheduleArgs = {
  schema: CreateScheduleSchema;
};

export type MutationCreateScheduleAppointmentByClientArgs = {
  schema: CreateScheduleAppointmentByClientSchema;
};

export type MutationCreateScheduleAppointmentByDoctorArgs = {
  schema: CreateScheduleAppointmentByDoctorSchema;
};

export type MutationCreateScheduleAppointmentByPatientArgs = {
  schema: CreateScheduleAppointmentByPatientSchema;
};

export type MutationCreateScheduleWaitlistByClientArgs = {
  schema: CreateScheduleWaitlistByClientSchema;
};

export type MutationCreateScheduleWaitlistByPatientArgs = {
  schema: CreateScheduleWaitlistByPatientSchema;
};

export type MutationCreateServiceArgs = {
  schema: CreateServiceSchema;
};

export type MutationCreateServiceCategoryArgs = {
  schema: CreateServiceCategorySchema;
};

export type MutationCreateSurveyArgs = {
  schema: CreateSurveySchema;
};

export type MutationCreateTemplateArgs = {
  schema: CreateTemplateSchema;
};

export type MutationDeleteClinicByIdArgs = {
  schema: DeleteClinicByIdSchema;
};

export type MutationDeleteClinicLogoFileIdArgs = {
  schema: DeleteClinicLogoFileIdSchema;
};

export type MutationDeleteClinicRoomByIdArgs = {
  schema: DeleteClinicRoomByIdSchema;
};

export type MutationDeleteClinicWorkingDayByIdArgs = {
  schema: DeleteClinicWorkingDayByIdSchema;
};

export type MutationDeleteDoctorByIdArgs = {
  schema: DeleteDoctorByIdSchema;
};

export type MutationDeletePatientByIdArgs = {
  schema: DeletePatientByIdSchema;
};

export type MutationDeleteScheduleByIdByClientArgs = {
  schema: DeleteScheduleByIdByClientSchema;
};

export type MutationDeleteServiceByIdArgs = {
  schema: DeleteServiceByIdSchema;
};

export type MutationDeleteServiceCategoryByIdArgs = {
  schema: DeleteServiceCategoryByIdSchema;
};

export type MutationEnable2FaArgs = {
  schema: EnableTwoFactorAuthenticationSchema;
};

export type MutationLocalSignInArgs = {
  schema: LocalSignInSchema;
};

export type MutationLocalSignInByAdminArgs = {
  schema: LocalSignInByAdminSchema;
};

export type MutationLocalSignUpArgs = {
  schema: LocalSignUpSchema;
};

export type MutationRateScheduleAppointmentByPatientArgs = {
  schema: RateScheduleAppointmentByPatientSchema;
};

export type MutationRequestEmailChangeArgs = {
  schema: RequestEmailChangeSchema;
};

export type MutationRequestPasswordRestorageArgs = {
  schema: RequestPasswordRestorageSchema;
};

export type MutationRescheduleAppointmentByClientArgs = {
  schema: RescheduleScheduleAppointmentByClientSchema;
};

export type MutationRescheduleScheduleAppointmentByPatientArgs = {
  schema: RescheduleScheduleAppointmentByPatientSchema;
};

export type MutationRescheduleWaitlistByClientArgs = {
  schema: RescheduleScheduleWaitlistByClientSchema;
};

export type MutationRescheduleWaitlistByPatientArgs = {
  schema: RescheduleScheduleWaitlistByPatientSchema;
};

export type MutationRestorePasswordArgs = {
  schema: RestorePasswordSchema;
};

export type MutationSendRawSmsByClientArgs = {
  schema: SendRawSmsByClientSchema;
};

export type MutationUnattachDoctorServiceArgs = {
  schema: UnattachServiceDoctorSchema;
};

export type MutationUpdateClinicByIdArgs = {
  schema: UpdateClinicByIdSchema;
};

export type MutationUpdateClinicConstraintsArgs = {
  schema: UpdateClinicConstraintsSchema;
};

export type MutationUpdateClinicLogoFileIdArgs = {
  schema: UpdateClinicLogoFileIdSchema;
};

export type MutationUpdateClinicMessagingServiceSidByAdminArgs = {
  schema: UpdateClinicMessagingServiceSidSchema;
};

export type MutationUpdateClinicRoomByIdArgs = {
  schema: UpdateClinicRoomByIdSchema;
};

export type MutationUpdateClinicWorkingDaysByClinicIdArgs = {
  schema: UpdateClinicWorkingDaysByClinicIdSchema;
};

export type MutationUpdateCurrentClinicTemplateArgs = {
  schema: UpdateCurrentClinicTemplateSchema;
};

export type MutationUpdateDoctorAvatarFileIdByClientArgs = {
  schema: UpdateDoctorAvatarFileIdByClientSchema;
};

export type MutationUpdateDoctorByIdByClientArgs = {
  schema: UpdateDoctorByIdByClientSchema;
};

export type MutationUpdateDoctorByUserIdByDoctorArgs = {
  schema: UpdateDoctorByUserIdByDoctorSchema;
};

export type MutationUpdateDoctorClinicsByClientArgs = {
  schema: UpdateDoctorClinicsByClientSchema;
};

export type MutationUpdateFormByClientArgs = {
  schema: UpdateFormSchema;
};

export type MutationUpdateMailTemplateByClientArgs = {
  schema: UpdateMailTemplateByClientSchema;
};

export type MutationUpdatePatientByIdArgs = {
  schema: UpdatePatientByIdSchema;
};

export type MutationUpdateScheduleAppointmentMissedStatusByClientArgs = {
  schema: UpdateScheduleAppointmentMissedByClientSchema;
};

export type MutationUpdateScheduleAppointmentStatusByIdByClientArgs = {
  schema: UpdateScheduleAppointmentStatusByIdByClientSchema;
};

export type MutationUpdateScheduleByIdByClientArgs = {
  schema: UpdateScheduleByIdByClientSchema;
};

export type MutationUpdateServiceByIdArgs = {
  schema: UpdateServiceByIdSchema;
};

export type MutationUpdateServiceCategoryByIdArgs = {
  schema: UpdateServiceCategoryByIdSchema;
};

export type MutationUpdateSmsTemplateByClientArgs = {
  schema: UpdateSmsTemplateByClientSchema;
};

export type MutationUpdateTemplateActionsByClientArgs = {
  schema: UpdateTemplateActionsByClientSchema;
};

export type MutationUpdateTemplateByClientArgs = {
  schema: UpdateTemplateByClientSchema;
};

export type MutationUpdateUserAvatarFileIdArgs = {
  schema: UpdateUserAvatarFileIdSchema;
};

export type MutationUploadFileArgs = {
  schema: UploadFileSchema;
};

export type PatientEntitiesResponse = {
  __typename?: "PatientEntitiesResponse";
  patients: Array<PatientEntityResponse>;
};

export type PatientEntityResponse = {
  __typename?: "PatientEntityResponse";
  activated: Scalars["Boolean"]["output"];
  address?: Maybe<Scalars["String"]["output"]>;
  allergens?: Maybe<Scalars["String"]["output"]>;
  allergic?: Maybe<Scalars["Boolean"]["output"]>;
  comment?: Maybe<Scalars["String"]["output"]>;
  companyId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  dateOfBirth?: Maybe<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  emailDistribution: Scalars["Boolean"]["output"];
  firstName: Scalars["String"]["output"];
  gender?: Maybe<PatientGenders>;
  id: Scalars["String"]["output"];
  languageISOCode: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  passportNumber?: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  postCode?: Maybe<Scalars["String"]["output"]>;
  smsDistribution: Scalars["Boolean"]["output"];
  status: PatientStatuses;
};

/** Enum of patient genders */
export enum PatientGenders {
  Female = "Female",
  Male = "Male",
}

/** Enum of patient sort fields */
export enum PatientSortFields {
  CreatedAt = "CreatedAt",
}

/** Enum of patient statuses */
export enum PatientStatuses {
  Existing = "Existing",
  New = "New",
}

export type PatientsCountResponse = {
  __typename?: "PatientsCountResponse";
  count: Scalars["Int"]["output"];
};

export type PinCodeExistsByUserIdResponse = {
  __typename?: "PinCodeExistsByUserIdResponse";
  exists: Scalars["Boolean"]["output"];
};

export type Query = {
  __typename?: "Query";
  autocompleteByClient: AutocompleteResultByClientResponse;
  autocompleteByDoctor: AutocompleteResultByDoctorResponse;
  calculateAverageScheduleAppointmentRateByClient: CalculateAverageScheduleAppointmentRateResponse;
  calculateAverageScheduleAppointmentRateByDoctor: CalculateAverageScheduleAppointmentRateResponse;
  calculateClinicConstraintsByClient: ClinicConstraintResponse;
  calculateClinicConstraintsByDoctor: ClinicConstraintResponse;
  checkIfCompanyExistsByUserId: CheckIfCompanyExistsByUserIdResponse;
  countAndGroupScheduleAppointmentsByDateByClient: ScheduleAppointmentCountsGroupedByDateResponse;
  countAndGroupScheduleAppointmentsByDateByDoctor: ScheduleAppointmentCountsGroupedByDateResponse;
  countNewPatientsByClient: PatientsCountResponse;
  countNewPatientsByDoctor: PatientsCountResponse;
  countScheduleAppointmentsByClient: ScheduleAppointmentsCountResponse;
  countScheduleAppointmentsByStatusesByClient: ScheduleAppointmentsCountByStatusesResponse;
  countScheduleAppointmentsByStatusesByDoctor: ScheduleAppointmentsCountByStatusesResponse;
  findAllCountries: FindAllCountriesResponse;
  findAndCountManyBlankFormsByClient: CountedBlankFormEntitiesResponse;
  findAndCountManyClinics: CountedClinicsResponse;
  findAndCountManyClinicsByAdmin: CountedClinicsByAdminResponse;
  findAndCountManyClinicsByClientId: CountedClinicsResponse;
  findAndCountManyDoctors: CountedAggregatedDoctorEntitiesResponse;
  findAndCountManyDoctorsByClinicId: CountedAggregatedDoctorEntitiesResponse;
  findAndCountManyFilledFormsByClient: CountedAggregatedFilledFormsResponse;
  findAndCountManyFilledFormsByDoctor: CountedAggregatedFilledFormsResponse;
  findAndCountManyLastSmsByClient: CountedAggregatedSmsEntitiesResponse;
  findAndCountManyPatientsByClient: CountedPatientsResponse;
  findAndCountManyPatientsByDoctor: CountedPatientsResponse;
  findAndCountManyPatientsInWaitlistsByClient: CountedPatientsResponse;
  findAndCountManyScheduleAppointmentsWithRelationsByClient: CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse;
  findAndCountManyScheduleAppointmentsWithRelationsByDoctor: CountedAggregatedScheduleAppointmentEntitiesWithRelationsByDoctorResponse;
  findAndCountManyScheduleWaitlistsByClient: CountedAggregatedScheduleWaitlistEntitiesByClientResponse;
  findAndCountManySmsByClient: CountedSmsEntitiesResponse;
  findAndCountManyTemplatesByClient: CountedTemplatesResponse;
  findBlankFormByServiceId: BlankFormEntityResponse;
  findClinicById: ClinicEntityResponse;
  findClinicRoomById: ClinicRoomEntityResponse;
  findCompanyByUserId: CompanyEntityResponse;
  findDefaultMailTemplate: MailTemplateResponse;
  findDefaultSmsTemplate: SmsTemplateResponse;
  findDoctorById: AggregatedDoctorEntityResponse;
  findManyAggregatedDoctorsByServiceId: AggregatedDoctorEntitiesResponse;
  findManyAvailableSlots: FindManyAvailableSlotsResponse;
  findManyAvailableSlotsByDateRange: FindManyAvailableSlotsResponse;
  findManyClinicRoomsByClinicId: FindManyRoomsByClinicIdResponse;
  findManyClinicWorkingDaysByClinicId: FindManyClinicWorkingDaysByClinicIdResponse;
  findManyCurrencies: CurrencyEntitiesResponse;
  findManyDoctorClinicsByDoctorId: FindManyDoctorClinicsByDoctorIdResponse;
  findManyDoctorTypesByLanguageISOCode: DoctorTypeEntitiesResponse;
  findManyFilesByIds: FindManyFilesByIdsResponse;
  findManyFormsPatientsByClient: PatientEntitiesResponse;
  findManyScheduleAppointmentUpdatesByIdByClient: ScheduleAppointmentUpdateEntitiesResponse;
  findManySchedulesByClient: ScheduleEntitiesResponse;
  findManySchedulesByDoctor: ScheduleEntitiesResponse;
  findManyServiceCategoriesByClinicId: FindManyServiceCategoriesByClinicIdResponse;
  findManyServices: ServiceEntitiesWithRelationsResponse;
  findManyServicesByPatient: ServiceEntitiesWithRelationsResponse;
  findManyTemplateVariablesByClient: FindManyTemplateVariablesByClientResponse;
  findPatientById: PatientEntityResponse;
  findScheduleAppointmentWithRelationsByHashByPatient: AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse;
  findScheduleWaitlistByHashByPatient: AggregatedScheduleWaitlistEntityByPatientResponse;
  findSentTemplateCountByEventByClient: FindSentTemplateCountByEventResponse;
  findSentTemplateCountByEventByDoctor: FindSentTemplateCountByEventResponse;
  findServiceById: ServiceEntityWithRelationsResponse;
  findServiceCategoryById: ServiceCategoryEntityResponse;
  findSystemEvents: FindSystemEventsResponse;
  findTemplateByClient: TemplateEntityResponse;
  getCurrentClient: ClientEntityResponse;
  getCurrentDoctor: DoctorEntityResponse;
  getCurrentUser: UserEntityResponse;
  helloWorld: Scalars["String"]["output"];
  pinCodeExistsByUserId: PinCodeExistsByUserIdResponse;
  scheduleAppointmentConfirmedByPatient: ScheduleAppointmentConfirmedByPatientResponse;
  scheduleWaitlistConfirmedByPatient: ScheduleAppointmentConfirmedByPatientResponse;
  searchByClient: SearchResultByClientResponse;
  searchByDoctor: SearchResultByDoctorResponse;
};

export type QueryAutocompleteByClientArgs = {
  schema: AutocompleteByClientSchema;
};

export type QueryAutocompleteByDoctorArgs = {
  schema: AutocompleteByDoctorSchema;
};

export type QueryCalculateAverageScheduleAppointmentRateByClientArgs = {
  schema: CalculateAverageScheduleAppointmentRateByClientSchema;
};

export type QueryCalculateAverageScheduleAppointmentRateByDoctorArgs = {
  schema: CalculateAverageScheduleAppointmentRateSchema;
};

export type QueryCalculateClinicConstraintsByClientArgs = {
  schema: CalculateClinicConstraintsByClientSchema;
};

export type QueryCalculateClinicConstraintsByDoctorArgs = {
  schema: CalculateClinicConstraintsByClientSchema;
};

export type QueryCountAndGroupScheduleAppointmentsByDateByClientArgs = {
  schema?: InputMaybe<CountAndGroupScheduleAppointmentsByDateByClientSchema>;
};

export type QueryCountAndGroupScheduleAppointmentsByDateByDoctorArgs = {
  schema?: InputMaybe<CountAndGroupScheduleAppointmentsByDateByDoctorSchema>;
};

export type QueryCountNewPatientsByClientArgs = {
  schema: CountNewPatientsByClientSchema;
};

export type QueryCountNewPatientsByDoctorArgs = {
  schema: CountNewPatientsByDoctorSchema;
};

export type QueryCountScheduleAppointmentsByClientArgs = {
  schema?: InputMaybe<CountScheduleAppointmentsCountByClientSchema>;
};

export type QueryCountScheduleAppointmentsByStatusesByClientArgs = {
  schema?: InputMaybe<CountScheduleAppointmentsByStatusesByClientSchema>;
};

export type QueryCountScheduleAppointmentsByStatusesByDoctorArgs = {
  schema?: InputMaybe<CountScheduleAppointmentsByStatusesByDoctorSchema>;
};

export type QueryFindAndCountManyBlankFormsByClientArgs = {
  schema: FindAndCountManyFormsByClientSchema;
};

export type QueryFindAndCountManyClinicsArgs = {
  schema: FindAndCountManyClinics;
};

export type QueryFindAndCountManyClinicsByAdminArgs = {
  schema: FindAndCountManyClinics;
};

export type QueryFindAndCountManyClinicsByClientIdArgs = {
  schema: FindAndCountManyClinicsByClientIdSchema;
};

export type QueryFindAndCountManyDoctorsArgs = {
  schema: FindAndCountManyDoctorsByUserIdSchema;
};

export type QueryFindAndCountManyDoctorsByClinicIdArgs = {
  schema: FindAndCountManyDoctorsByClinicIdSchema;
};

export type QueryFindAndCountManyFilledFormsByClientArgs = {
  schema: FindAndCountManyFormsByClientSchema;
};

export type QueryFindAndCountManyFilledFormsByDoctorArgs = {
  schema: FindAndCountManyFormsByDoctorSchema;
};

export type QueryFindAndCountManyLastSmsByClientArgs = {
  schema: FindAndCountManyLastSmsByClientSchema;
};

export type QueryFindAndCountManyPatientsByClientArgs = {
  schema: FindAndCountManyPatientsByClientSchema;
};

export type QueryFindAndCountManyPatientsByDoctorArgs = {
  schema: FindAndCountManyPatientsByDoctorSchema;
};

export type QueryFindAndCountManyPatientsInWaitlistsByClientArgs = {
  schema: FindAndCountManyPatientsByClientSchema;
};

export type QueryFindAndCountManyScheduleAppointmentsWithRelationsByClientArgs =
  {
    schema: FindAndCountManyScheduleAppointmentsWithRelationsByClientSchema;
  };

export type QueryFindAndCountManyScheduleAppointmentsWithRelationsByDoctorArgs =
  {
    schema: FindAndCountManyScheduleAppointmentsWithRelationsByDoctorSchema;
  };

export type QueryFindAndCountManyScheduleWaitlistsByClientArgs = {
  schema: FindAndCountManyScheduleWaitlistsByClientSchema;
};

export type QueryFindAndCountManySmsByClientArgs = {
  schema: FindAndCountManySmsByClientSchema;
};

export type QueryFindAndCountManyTemplatesByClientArgs = {
  schema: FindAndCountManyTemplatesByClientSchema;
};

export type QueryFindBlankFormByServiceIdArgs = {
  schema: FindBlankFormByServiceIdSchema;
};

export type QueryFindClinicByIdArgs = {
  schema: FindClinicByIdSchema;
};

export type QueryFindClinicRoomByIdArgs = {
  schema: FindClinicRoomByIdSchema;
};

export type QueryFindDefaultMailTemplateArgs = {
  schema: FindDefaultMailTemplateSchema;
};

export type QueryFindDefaultSmsTemplateArgs = {
  schema: FindDefaultSmsTemplateSchema;
};

export type QueryFindDoctorByIdArgs = {
  schema: FindDoctorByIdSchema;
};

export type QueryFindManyAggregatedDoctorsByServiceIdArgs = {
  schema: FindManyAggregatedDoctorsByServiceIdSchema;
};

export type QueryFindManyAvailableSlotsArgs = {
  schema: FindManyAvailableSlotsSchema;
};

export type QueryFindManyAvailableSlotsByDateRangeArgs = {
  schema: FindManyAvailableSlotsByDateRangeSchema;
};

export type QueryFindManyClinicRoomsByClinicIdArgs = {
  schema: FindManyRoomsByClinicIdSchema;
};

export type QueryFindManyClinicWorkingDaysByClinicIdArgs = {
  schema: FindManyClinicWorkingDaysByClinicIdSchema;
};

export type QueryFindManyDoctorClinicsByDoctorIdArgs = {
  schema: FindManyDoctorClinicsByDoctorIdSchema;
};

export type QueryFindManyDoctorTypesByLanguageIsoCodeArgs = {
  schema: FindManyDoctorTypesByLanguageIsoCodeSchema;
};

export type QueryFindManyFilesByIdsArgs = {
  schema: FindManyFilesByIdsSchema;
};

export type QueryFindManyScheduleAppointmentUpdatesByIdByClientArgs = {
  schema: FindManyScheduleAppointmentUpdatesByIdByClientSchema;
};

export type QueryFindManySchedulesByClientArgs = {
  schema: FindManySchedulesByClientSchema;
};

export type QueryFindManySchedulesByDoctorArgs = {
  schema: FindManySchedulesByDoctorSchema;
};

export type QueryFindManyServiceCategoriesByClinicIdArgs = {
  schema: FindManyServiceCategoriesByClinicIdSchema;
};

export type QueryFindManyServicesArgs = {
  schema: FindManyServicesSchema;
};

export type QueryFindManyServicesByPatientArgs = {
  schema: FindManyServicesSchema;
};

export type QueryFindManyTemplateVariablesByClientArgs = {
  schema: FindManyTemplateVariablesByClientSchema;
};

export type QueryFindPatientByIdArgs = {
  schema: FindPatientByIdSchema;
};

export type QueryFindScheduleAppointmentWithRelationsByHashByPatientArgs = {
  schema: FindScheduleAppointmentWithRelationsByHashByPatientSchema;
};

export type QueryFindScheduleWaitlistByHashByPatientArgs = {
  schema: FindScheduleAppointmentWithRelationsByHashByPatientSchema;
};

export type QueryFindSentTemplateCountByEventByClientArgs = {
  schema: FindSentTemplateCountByEventSchema;
};

export type QueryFindSentTemplateCountByEventByDoctorArgs = {
  schema: FindSentTemplateCountByEventSchema;
};

export type QueryFindServiceByIdArgs = {
  schema: FindServiceByIdSchema;
};

export type QueryFindServiceCategoryByIdArgs = {
  schema: FindServiceCategoryByIdSchema;
};

export type QueryFindTemplateByClientArgs = {
  schema: FindTemplateByClientSchema;
};

export type QueryScheduleAppointmentConfirmedByPatientArgs = {
  schema: ScheduleAppointmentConfirmedByPatientSchema;
};

export type QueryScheduleWaitlistConfirmedByPatientArgs = {
  schema: ScheduleAppointmentConfirmedByPatientSchema;
};

export type QuerySearchByClientArgs = {
  schema: SearchByClientSchema;
};

export type QuerySearchByDoctorArgs = {
  schema: SearchByDoctorSchema;
};

export type RateScheduleAppointmentByPatientResponse = {
  __typename?: "RateScheduleAppointmentByPatientResponse";
  rated: Scalars["Boolean"]["output"];
};

export type RateScheduleAppointmentByPatientSchema = {
  hash: Scalars["String"]["input"];
  rate: Scalars["Int"]["input"];
};

export type RequestEmailChangeResponse = {
  __typename?: "RequestEmailChangeResponse";
  requested: Scalars["Boolean"]["output"];
};

export type RequestEmailChangeSchema = {
  email: Scalars["String"]["input"];
};

export type RequestPasswordRestorageResponse = {
  __typename?: "RequestPasswordRestorageResponse";
  requested: Scalars["Boolean"]["output"];
};

export type RequestPasswordRestorageSchema = {
  email: Scalars["String"]["input"];
  languageISOCode: LanguageIsoCodes;
};

export type RescheduleScheduleAppointmentByClientResponse = {
  __typename?: "RescheduleScheduleAppointmentByClientResponse";
  rescheduled: Scalars["Boolean"]["output"];
};

export type RescheduleScheduleAppointmentByClientSchema = {
  appointmentId: Scalars["String"]["input"];
  date: Scalars["String"]["input"];
  minuteFrom: Scalars["Int"]["input"];
  minuteTo: Scalars["Int"]["input"];
};

export type RescheduleScheduleAppointmentByPatientResponse = {
  __typename?: "RescheduleScheduleAppointmentByPatientResponse";
  rescheduled: Scalars["Boolean"]["output"];
};

export type RescheduleScheduleAppointmentByPatientSchema = {
  date: Scalars["String"]["input"];
  doctorId?: InputMaybe<Scalars["String"]["input"]>;
  hash: Scalars["String"]["input"];
  minuteFrom: Scalars["Int"]["input"];
  minuteTo: Scalars["Int"]["input"];
};

export type RescheduleScheduleWaitlistByClientResponse = {
  __typename?: "RescheduleScheduleWaitlistByClientResponse";
  rescheduled: Scalars["Boolean"]["output"];
};

export type RescheduleScheduleWaitlistByClientSchema = {
  dateFrom?: InputMaybe<Scalars["String"]["input"]>;
  dateTo?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  minuteFrom?: InputMaybe<Scalars["Int"]["input"]>;
  minuteTo?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RescheduleScheduleWaitlistByPatientResponse = {
  __typename?: "RescheduleScheduleWaitlistByPatientResponse";
  rescheduled: Scalars["Boolean"]["output"];
};

export type RescheduleScheduleWaitlistByPatientSchema = {
  dateFrom?: InputMaybe<Scalars["String"]["input"]>;
  dateTo?: InputMaybe<Scalars["String"]["input"]>;
  hash: Scalars["String"]["input"];
  minuteFrom?: InputMaybe<Scalars["Int"]["input"]>;
  minuteTo?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RestorePasswordResponse = {
  __typename?: "RestorePasswordResponse";
  restored: Scalars["Boolean"]["output"];
};

export type RestorePasswordSchema = {
  confirmationHash: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type ScheduleAppointmentConfirmedByPatientResponse = {
  __typename?: "ScheduleAppointmentConfirmedByPatientResponse";
  confirmed: Scalars["Boolean"]["output"];
};

export type ScheduleAppointmentConfirmedByPatientSchema = {
  hash: Scalars["String"]["input"];
};

export type ScheduleAppointmentCountGroupedByDateResponse = {
  __typename?: "ScheduleAppointmentCountGroupedByDateResponse";
  count: Scalars["Float"]["output"];
  date: Scalars["String"]["output"];
};

export type ScheduleAppointmentCountsGroupedByDateResponse = {
  __typename?: "ScheduleAppointmentCountsGroupedByDateResponse";
  counts: Array<ScheduleAppointmentCountGroupedByDateResponse>;
};

export type ScheduleAppointmentEntityResponse = {
  __typename?: "ScheduleAppointmentEntityResponse";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  patientId: Scalars["String"]["output"];
  scheduleId: Scalars["String"]["output"];
  status: AppointmentStatuses;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ScheduleAppointmentEntityWithRelationsResponse = {
  __typename?: "ScheduleAppointmentEntityWithRelationsResponse";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  patientId: Scalars["String"]["output"];
  schedule: ScheduleEntityResponse;
  scheduleId: Scalars["String"]["output"];
  status: AppointmentStatuses;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ScheduleAppointmentUpdateEntitiesResponse = {
  __typename?: "ScheduleAppointmentUpdateEntitiesResponse";
  appointmentUpdates: Array<ScheduleAppointmentUpdateEntityResponse>;
};

export type ScheduleAppointmentUpdateEntityResponse = {
  __typename?: "ScheduleAppointmentUpdateEntityResponse";
  appointmentId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  original: ScheduleAppointmentEntityWithRelationsResponse;
  updated: ScheduleAppointmentEntityWithRelationsResponse;
  userId: Scalars["String"]["output"];
};

export type ScheduleAppointmentsCountByStatusesResponse = {
  __typename?: "ScheduleAppointmentsCountByStatusesResponse";
  all: Scalars["Int"]["output"];
  canceled: Scalars["Int"]["output"];
  confirmedByApplication: Scalars["Int"]["output"];
  confirmedByPatient: Scalars["Int"]["output"];
  missed: Scalars["Int"]["output"];
  new: Scalars["Int"]["output"];
  successful: Scalars["Int"]["output"];
};

export type ScheduleAppointmentsCountResponse = {
  __typename?: "ScheduleAppointmentsCountResponse";
  count: Scalars["Int"]["output"];
};

export type ScheduleEntitiesResponse = {
  __typename?: "ScheduleEntitiesResponse";
  schedules: Array<ScheduleEntityResponse>;
};

export type ScheduleEntityResponse = {
  __typename?: "ScheduleEntityResponse";
  clinicId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  dateFrom?: Maybe<Scalars["DateTime"]["output"]>;
  dateTo?: Maybe<Scalars["DateTime"]["output"]>;
  daysOfWeek?: Maybe<Array<DaysOfWeek>>;
  doctorId: Scalars["String"]["output"];
  eventType: EventTypes;
  id: Scalars["String"]["output"];
  layoutType: LayoutTypes;
  minuteFrom: Scalars["Float"]["output"];
  minuteTo: Scalars["Float"]["output"];
  roomId?: Maybe<Scalars["String"]["output"]>;
  serviceId?: Maybe<Scalars["String"]["output"]>;
};

export type ScheduleWaitlistEntityResponse = {
  __typename?: "ScheduleWaitlistEntityResponse";
  clinicId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  dateFrom: Scalars["String"]["output"];
  dateTo: Scalars["String"]["output"];
  doctorId?: Maybe<Scalars["String"]["output"]>;
  hash: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  minuteFrom: Scalars["Int"]["output"];
  minuteTo: Scalars["Int"]["output"];
  patientId: Scalars["String"]["output"];
  serviceId: Scalars["String"]["output"];
  status: WaitlistStatuses;
  uncommittedPatientId?: Maybe<Scalars["String"]["output"]>;
};

export type SearchByClientSchema = {
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  query: Scalars["String"]["input"];
  type: SearchTypes;
};

export type SearchByClientUnion =
  | AggregatedScheduleAppointmentEntityWithRelationsByClientResponse
  | PatientEntityResponse;

export type SearchByDoctorSchema = {
  limit: Scalars["Int"]["input"];
  offset: Scalars["Int"]["input"];
  query: Scalars["String"]["input"];
  type: SearchTypes;
};

export type SearchByDoctorUnion =
  | AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse
  | PatientEntityResponse;

export type SearchResultByClientResponse = {
  __typename?: "SearchResultByClientResponse";
  count: Scalars["Float"]["output"];
  entities?: Maybe<Array<SearchByClientUnion>>;
};

export type SearchResultByDoctorResponse = {
  __typename?: "SearchResultByDoctorResponse";
  count: Scalars["Float"]["output"];
  entities?: Maybe<Array<SearchByDoctorUnion>>;
};

/** Enum of search types */
export enum SearchTypes {
  Appointment = "Appointment",
  Patient = "Patient",
}

export type SendRawSmsByClientSchema = {
  clinicId: Scalars["String"]["input"];
  content: Scalars["String"]["input"];
  patientId: Scalars["String"]["input"];
};

export type SendRawSmsResponse = {
  __typename?: "SendRawSmsResponse";
  sent: Scalars["Boolean"]["output"];
};

export type ServiceCategoryEntityResponse = {
  __typename?: "ServiceCategoryEntityResponse";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type ServiceEntitiesWithRelationsResponse = {
  __typename?: "ServiceEntitiesWithRelationsResponse";
  services: Array<ServiceEntityWithRelationsResponse>;
};

export type ServiceEntityResponse = {
  __typename?: "ServiceEntityResponse";
  category?: Maybe<ServiceCategoryEntityResponse>;
  createdAt: Scalars["DateTime"]["output"];
  currencyCode?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  durationInMinutes: Scalars["Float"]["output"];
  hiddenFromPatients: Scalars["Boolean"]["output"];
  icd9Code?: Maybe<Scalars["String"]["output"]>;
  icd10Code?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  price?: Maybe<Scalars["Float"]["output"]>;
};

export type ServiceEntityWithRelationsResponse = {
  __typename?: "ServiceEntityWithRelationsResponse";
  category?: Maybe<ServiceCategoryEntityResponse>;
  clinicRooms?: Maybe<Array<ClinicRoomEntityResponse>>;
  createdAt: Scalars["DateTime"]["output"];
  currencyCode?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  doctorIds?: Maybe<Array<Scalars["String"]["output"]>>;
  durationInMinutes: Scalars["Float"]["output"];
  hiddenFromPatients: Scalars["Boolean"]["output"];
  icd9Code?: Maybe<Scalars["String"]["output"]>;
  icd10Code?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  price?: Maybe<Scalars["Float"]["output"]>;
};

/** Enum of service sort fields */
export enum ServiceSortFields {
  CreatedAt = "CreatedAt",
}

export type SlotResponse = {
  __typename?: "SlotResponse";
  from: Scalars["String"]["output"];
  to: Scalars["String"]["output"];
};

export type SlotsGroupedByDateResponse = {
  __typename?: "SlotsGroupedByDateResponse";
  date: Scalars["String"]["output"];
  slots: Array<SlotResponse>;
};

export type SmsEntityResponse = {
  __typename?: "SmsEntityResponse";
  clinicId: Scalars["String"]["output"];
  clinicToPatient: Scalars["Boolean"]["output"];
  content: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  from: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  patientId: Scalars["String"]["output"];
  to: Scalars["String"]["output"];
};

/** Enum of sorting fields */
export enum SmsSortFields {
  CreatedAt = "CreatedAt",
}

export type SmsTemplateEntityResponse = {
  __typename?: "SmsTemplateEntityResponse";
  createdAt: Scalars["DateTime"]["output"];
  delayInHours: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  languageISOCode: Scalars["String"]["output"];
  templateId: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type SmsTemplateResponse = {
  __typename?: "SmsTemplateResponse";
  text: Scalars["String"]["output"];
};

/** Enum of sorting orders */
export enum SortingOrders {
  Ascending = "Ascending",
  Descending = "Descending",
}

export type SurveyEntityResponse = {
  __typename?: "SurveyEntityResponse";
  clinicId: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  finalMessage: Scalars["String"]["output"];
  followUpResponse: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type TemplateEntityResponse = {
  __typename?: "TemplateEntityResponse";
  actions: Array<DistributionActions>;
  clinicId: Scalars["String"]["output"];
  clinicsTemplates?: Maybe<ClinicsTemplatesResponse>;
  createdAt: Scalars["DateTime"]["output"];
  event: DistributionSystemEvents;
  id: Scalars["String"]["output"];
  mailSent: Scalars["Float"]["output"];
  mailTemplates?: Maybe<Array<MailTemplateEntityResponse>>;
  name: Scalars["String"]["output"];
  semanticLock: Scalars["String"]["output"];
  smsSent: Scalars["Float"]["output"];
  smsTemplates?: Maybe<Array<SmsTemplateEntityResponse>>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

/** Enum of template sort fields */
export enum TemplateSortFields {
  CreatedAt = "CreatedAt",
}

export type UnattachServiceDoctorResponse = {
  __typename?: "UnattachServiceDoctorResponse";
  unattached: Scalars["Boolean"]["output"];
};

export type UnattachServiceDoctorSchema = {
  doctorId: Scalars["String"]["input"];
  serviceId: Scalars["String"]["input"];
};

export type UpdateClinicByIdSchema = {
  fields: UpdateClinicFieldsSchema;
  id: Scalars["String"]["input"];
};

export type UpdateClinicConstraintsFieldsSchema = {
  mailLimit?: InputMaybe<Scalars["Int"]["input"]>;
  mailSent?: InputMaybe<Scalars["Int"]["input"]>;
  smsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  smsSent?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateClinicConstraintsSchema = {
  fields: UpdateClinicConstraintsFieldsSchema;
  id: Scalars["String"]["input"];
};

export type UpdateClinicFieldsSchema = {
  address?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  geographicalCoordinates?: InputMaybe<UpdateClinicGeographicalCoordinatesSchema>;
  hiddenFromPatients?: InputMaybe<Scalars["Boolean"]["input"]>;
  houseNumber?: InputMaybe<Scalars["String"]["input"]>;
  index?: InputMaybe<Scalars["String"]["input"]>;
  licenseNumber?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  rooms?: InputMaybe<Array<UpdateClinicRoomSchema>>;
  workingDays?: InputMaybe<Array<UpdateClinicWorkingDaySchema>>;
};

export type UpdateClinicGeographicalCoordinatesSchema = {
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
};

export type UpdateClinicLogoFileIdResponse = {
  __typename?: "UpdateClinicLogoFileIdResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateClinicLogoFileIdSchema = {
  id: Scalars["String"]["input"];
  logoFileId: Scalars["String"]["input"];
};

export type UpdateClinicMessagingServiceSidSchema = {
  id: Scalars["String"]["input"];
  messagingServiceSid?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateClinicRoomByIdFieldsSchema = {
  name: Scalars["String"]["input"];
};

export type UpdateClinicRoomByIdResponse = {
  __typename?: "UpdateClinicRoomByIdResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateClinicRoomByIdSchema = {
  fields: UpdateClinicRoomByIdFieldsSchema;
  id: Scalars["String"]["input"];
};

export type UpdateClinicRoomSchema = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
};

export type UpdateClinicWorkingDayByIdSchema = {
  day?: InputMaybe<ClinicWorkingDays>;
  fromMinute?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["String"]["input"];
  toMinute?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateClinicWorkingDaySchema = {
  day: ClinicWorkingDays;
  fromMinute: Scalars["Int"]["input"];
  id?: InputMaybe<Scalars["String"]["input"]>;
  toMinute: Scalars["Int"]["input"];
};

export type UpdateClinicWorkingDaysByClinicIdResponse = {
  __typename?: "UpdateClinicWorkingDaysByClinicIdResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateClinicWorkingDaysByClinicIdSchema = {
  clinicId: Scalars["String"]["input"];
  workingDays: Array<UpdateClinicWorkingDayByIdSchema>;
};

export type UpdateClinicsSchema = {
  clinicId: Scalars["String"]["input"];
  hiddenFromPatients: Scalars["Boolean"]["input"];
};

export type UpdateCurrentClinicTemplateSchema = {
  clinicId: Scalars["String"]["input"];
  event: DistributionSystemEvents;
  templateId?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateDoctorAvatarFileIdByClientSchema = {
  avatarFileId: Scalars["String"]["input"];
  doctorId: Scalars["String"]["input"];
};

export type UpdateDoctorByIdByClientSchema = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  doctorId: Scalars["String"]["input"];
  doctorTypeId?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  medicalCertificateNumber?: InputMaybe<Scalars["String"]["input"]>;
  passportNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateDoctorByUserIdByDoctorSchema = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  doctorTypeId?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  medicalCertificateNumber?: InputMaybe<Scalars["String"]["input"]>;
  passportNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  promoCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateDoctorClinicsByClientSchema = {
  clinics: Array<UpdateClinicsSchema>;
  doctorId: Scalars["String"]["input"];
};

export type UpdateDoctorClinicsResponse = {
  __typename?: "UpdateDoctorClinicsResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateDoctorResponse = {
  __typename?: "UpdateDoctorResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateFormFieldSchema = {
  hiddenFromExistingPatients?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Float"]["input"]>;
  required?: InputMaybe<Scalars["Boolean"]["input"]>;
  translations?: InputMaybe<Array<UpdateFormFieldTranslationSchema>>;
  type?: InputMaybe<FormFieldTypes>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateFormFieldTranslationSchema = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  languageISOCode?: InputMaybe<LanguageIsoCodes>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  placeholder?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateFormResponse = {
  __typename?: "UpdateFormResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateFormSchema = {
  fields?: InputMaybe<Array<UpdateFormFieldSchema>>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  serviceId?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateMailTemplateByClientSchema = {
  fields: UpdateMailTemplateFieldsSchema;
  id: Scalars["String"]["input"];
};

export type UpdateMailTemplateFieldsSchema = {
  delayInHours?: InputMaybe<Scalars["Int"]["input"]>;
  subject?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdatePatientByIdResponse = {
  __typename?: "UpdatePatientByIdResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdatePatientByIdSchema = {
  allergens?: InputMaybe<Scalars["String"]["input"]>;
  allergic?: InputMaybe<Scalars["Boolean"]["input"]>;
  comment?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  emailDistribution?: InputMaybe<Scalars["Boolean"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<PatientGenders>;
  id: Scalars["String"]["input"];
  languageISOCode: LanguageIsoCodes;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  passportNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  smsDistribution?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateScheduleAppointmentMissedByClientSchema = {
  id: Scalars["String"]["input"];
};

export type UpdateScheduleAppointmentMissedStatusByClientResponse = {
  __typename?: "UpdateScheduleAppointmentMissedStatusByClientResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateScheduleAppointmentStatusByIdByClientResponse = {
  __typename?: "UpdateScheduleAppointmentStatusByIdByClientResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateScheduleAppointmentStatusByIdByClientSchema = {
  id: Scalars["String"]["input"];
  status: AppointmentStatuses;
};

export type UpdateScheduleByIdByClientResponse = {
  __typename?: "UpdateScheduleByIdByClientResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateScheduleByIdByClientSchema = {
  fields: UpdateScheduleFieldsSchema;
  id: Scalars["String"]["input"];
};

export type UpdateScheduleFieldsSchema = {
  dateFrom?: InputMaybe<Scalars["String"]["input"]>;
  dateTo?: InputMaybe<Scalars["String"]["input"]>;
  daysOfWeek?: InputMaybe<Array<DaysOfWeek>>;
  eventType?: InputMaybe<EventTypes>;
  minuteFrom?: InputMaybe<Scalars["Int"]["input"]>;
  minuteTo?: InputMaybe<Scalars["Int"]["input"]>;
  roomId?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateServiceByIdResponse = {
  __typename?: "UpdateServiceByIdResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateServiceByIdSchema = {
  categoryId?: InputMaybe<Scalars["String"]["input"]>;
  currencyCode?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  durationInMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  hiddenFromPatients?: InputMaybe<Scalars["Boolean"]["input"]>;
  icd9Code?: InputMaybe<Scalars["String"]["input"]>;
  icd10Code?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<Scalars["Float"]["input"]>;
  roomIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type UpdateServiceCategoryByIdResponse = {
  __typename?: "UpdateServiceCategoryByIdResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateServiceCategoryByIdSchema = {
  fields: UpdateServiceCategoryFieldsSchema;
  id: Scalars["String"]["input"];
};

export type UpdateServiceCategoryFieldsSchema = {
  name: Scalars["String"]["input"];
};

export type UpdateSmsTemplateByClientSchema = {
  fields: UpdateSmsTemplateFieldsSchema;
  id: Scalars["String"]["input"];
};

export type UpdateSmsTemplateFieldsSchema = {
  delayInHours?: InputMaybe<Scalars["Int"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateTemplateActionsByClientResponse = {
  __typename?: "UpdateTemplateActionsByClientResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateTemplateActionsByClientSchema = {
  actions: Array<DistributionActions>;
  id: Scalars["String"]["input"];
};

export type UpdateTemplateByClientResponse = {
  __typename?: "UpdateTemplateByClientResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateTemplateByClientSchema = {
  fields: UpdateTemplateFieldsSchema;
  id: Scalars["String"]["input"];
};

export type UpdateTemplateFieldsSchema = {
  actions?: InputMaybe<Array<DistributionActions>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserAvatarFileIdResponse = {
  __typename?: "UpdateUserAvatarFileIdResponse";
  updated: Scalars["Boolean"]["output"];
};

export type UpdateUserAvatarFileIdSchema = {
  avatarFileId: Scalars["String"]["input"];
};

export type UploadFileSchema = {
  file: Scalars["Upload"]["input"];
  usageStatus?: InputMaybe<FileUsageStatuses>;
};

export type UserEntityResponse = {
  __typename?: "UserEntityResponse";
  activated: Scalars["Boolean"]["output"];
  avatarFileId?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  dateOfBirth?: Maybe<Scalars["DateTime"]["output"]>;
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  roles: Array<UserRoles>;
};

/** Enum of user roles */
export enum UserRoles {
  Client = "Client",
  Doctor = "Doctor",
  Superuser = "Superuser",
  SupportManager = "SupportManager",
}

/** Enum of waitlist statuses */
export enum WaitlistStatuses {
  Closed = "Closed",
  Completed = "Completed",
  Pending = "Pending",
  PendingUnconfirmed = "PendingUnconfirmed",
}

export type LocalSignInByAdminMutationVariables = Exact<{
  schema: LocalSignInByAdminSchema;
}>;

export type LocalSignInByAdminMutation = {
  __typename?: "Mutation";
  localSignInByAdmin: {
    __typename?: "LocalSignInByAdminResponse";
    signedIn: boolean;
  };
};

export type UpdateClinicConstraintsMutationVariables = Exact<{
  schema: UpdateClinicConstraintsSchema;
}>;

export type UpdateClinicConstraintsMutation = {
  __typename?: "Mutation";
  updateClinicConstraints: {
    __typename?: "UpdateTemplateByClientResponse";
    updated: boolean;
  };
};

export type UpdateClinicMessagingServiceSidByAdminMutationVariables = Exact<{
  schema: UpdateClinicMessagingServiceSidSchema;
}>;

export type UpdateClinicMessagingServiceSidByAdminMutation = {
  __typename?: "Mutation";
  updateClinicMessagingServiceSidByAdmin: {
    __typename?: "UpdateClinicLogoFileIdResponse";
    updated: boolean;
  };
};

export type FindAndCountManyClinicsByAdminQueryVariables = Exact<{
  schema: FindAndCountManyClinics;
}>;

export type FindAndCountManyClinicsByAdminQuery = {
  __typename?: "Query";
  findAndCountManyClinicsByAdmin: {
    __typename?: "CountedClinicsByAdminResponse";
    count: number;
    clinics: Array<{
      __typename?: "ClinicEntityByAdminResponse";
      phoneNumber: string;
      name: string;
      id: string;
      messagingServiceSid?: string | null;
    }>;
  };
};

export type FindAndCountManyConstraintsByAdminQueryVariables = Exact<{
  schema: FindAndCountManyClinics;
}>;

export type FindAndCountManyConstraintsByAdminQuery = {
  __typename?: "Query";
  findAndCountManyClinicsByAdmin: {
    __typename?: "CountedClinicsByAdminResponse";
    count: number;
    clinics: Array<{
      __typename?: "ClinicEntityByAdminResponse";
      name: string;
      constraints?: {
        __typename?: "ClinicConstraintEntityResponse";
        clinicId: string;
        id: string;
        mailLimit: number;
        mailSent: number;
        smsLimit: number;
        smsSent: number;
      } | null;
    }>;
  };
};

export const LocalSignInByAdminDocument = gql`
  mutation LocalSignInByAdmin($schema: LocalSignInByAdminSchema!) {
    localSignInByAdmin(schema: $schema) {
      signedIn
    }
  }
`;
export type LocalSignInByAdminMutationFn = Apollo.MutationFunction<
  LocalSignInByAdminMutation,
  LocalSignInByAdminMutationVariables
>;

/**
 * __useLocalSignInByAdminMutation__
 *
 * To run a mutation, you first call `useLocalSignInByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocalSignInByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [localSignInByAdminMutation, { data, loading, error }] = useLocalSignInByAdminMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useLocalSignInByAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LocalSignInByAdminMutation,
    LocalSignInByAdminMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LocalSignInByAdminMutation,
    LocalSignInByAdminMutationVariables
  >(LocalSignInByAdminDocument, options);
}
export type LocalSignInByAdminMutationHookResult = ReturnType<
  typeof useLocalSignInByAdminMutation
>;
export type LocalSignInByAdminMutationResult =
  Apollo.MutationResult<LocalSignInByAdminMutation>;
export type LocalSignInByAdminMutationOptions = Apollo.BaseMutationOptions<
  LocalSignInByAdminMutation,
  LocalSignInByAdminMutationVariables
>;
export const UpdateClinicConstraintsDocument = gql`
  mutation UpdateClinicConstraints($schema: UpdateClinicConstraintsSchema!) {
    updateClinicConstraints(schema: $schema) {
      updated
    }
  }
`;
export type UpdateClinicConstraintsMutationFn = Apollo.MutationFunction<
  UpdateClinicConstraintsMutation,
  UpdateClinicConstraintsMutationVariables
>;

/**
 * __useUpdateClinicConstraintsMutation__
 *
 * To run a mutation, you first call `useUpdateClinicConstraintsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicConstraintsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicConstraintsMutation, { data, loading, error }] = useUpdateClinicConstraintsMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateClinicConstraintsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClinicConstraintsMutation,
    UpdateClinicConstraintsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClinicConstraintsMutation,
    UpdateClinicConstraintsMutationVariables
  >(UpdateClinicConstraintsDocument, options);
}
export type UpdateClinicConstraintsMutationHookResult = ReturnType<
  typeof useUpdateClinicConstraintsMutation
>;
export type UpdateClinicConstraintsMutationResult =
  Apollo.MutationResult<UpdateClinicConstraintsMutation>;
export type UpdateClinicConstraintsMutationOptions = Apollo.BaseMutationOptions<
  UpdateClinicConstraintsMutation,
  UpdateClinicConstraintsMutationVariables
>;
export const UpdateClinicMessagingServiceSidByAdminDocument = gql`
  mutation UpdateClinicMessagingServiceSidByAdmin(
    $schema: UpdateClinicMessagingServiceSidSchema!
  ) {
    updateClinicMessagingServiceSidByAdmin(schema: $schema) {
      updated
    }
  }
`;
export type UpdateClinicMessagingServiceSidByAdminMutationFn =
  Apollo.MutationFunction<
    UpdateClinicMessagingServiceSidByAdminMutation,
    UpdateClinicMessagingServiceSidByAdminMutationVariables
  >;

/**
 * __useUpdateClinicMessagingServiceSidByAdminMutation__
 *
 * To run a mutation, you first call `useUpdateClinicMessagingServiceSidByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicMessagingServiceSidByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicMessagingServiceSidByAdminMutation, { data, loading, error }] = useUpdateClinicMessagingServiceSidByAdminMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateClinicMessagingServiceSidByAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClinicMessagingServiceSidByAdminMutation,
    UpdateClinicMessagingServiceSidByAdminMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClinicMessagingServiceSidByAdminMutation,
    UpdateClinicMessagingServiceSidByAdminMutationVariables
  >(UpdateClinicMessagingServiceSidByAdminDocument, options);
}
export type UpdateClinicMessagingServiceSidByAdminMutationHookResult =
  ReturnType<typeof useUpdateClinicMessagingServiceSidByAdminMutation>;
export type UpdateClinicMessagingServiceSidByAdminMutationResult =
  Apollo.MutationResult<UpdateClinicMessagingServiceSidByAdminMutation>;
export type UpdateClinicMessagingServiceSidByAdminMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClinicMessagingServiceSidByAdminMutation,
    UpdateClinicMessagingServiceSidByAdminMutationVariables
  >;
export const FindAndCountManyClinicsByAdminDocument = gql`
  query FindAndCountManyClinicsByAdmin($schema: FindAndCountManyClinics!) {
    findAndCountManyClinicsByAdmin(schema: $schema) {
      clinics {
        phoneNumber
        name
        id
        messagingServiceSid
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyClinicsByAdminQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyClinicsByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyClinicsByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyClinicsByAdminQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyClinicsByAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyClinicsByAdminQuery,
    FindAndCountManyClinicsByAdminQueryVariables
  > &
    (
      | {
          variables: FindAndCountManyClinicsByAdminQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyClinicsByAdminQuery,
    FindAndCountManyClinicsByAdminQueryVariables
  >(FindAndCountManyClinicsByAdminDocument, options);
}
export function useFindAndCountManyClinicsByAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyClinicsByAdminQuery,
    FindAndCountManyClinicsByAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyClinicsByAdminQuery,
    FindAndCountManyClinicsByAdminQueryVariables
  >(FindAndCountManyClinicsByAdminDocument, options);
}
export function useFindAndCountManyClinicsByAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FindAndCountManyClinicsByAdminQuery,
    FindAndCountManyClinicsByAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindAndCountManyClinicsByAdminQuery,
    FindAndCountManyClinicsByAdminQueryVariables
  >(FindAndCountManyClinicsByAdminDocument, options);
}
export type FindAndCountManyClinicsByAdminQueryHookResult = ReturnType<
  typeof useFindAndCountManyClinicsByAdminQuery
>;
export type FindAndCountManyClinicsByAdminLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyClinicsByAdminLazyQuery
>;
export type FindAndCountManyClinicsByAdminSuspenseQueryHookResult = ReturnType<
  typeof useFindAndCountManyClinicsByAdminSuspenseQuery
>;
export type FindAndCountManyClinicsByAdminQueryResult = Apollo.QueryResult<
  FindAndCountManyClinicsByAdminQuery,
  FindAndCountManyClinicsByAdminQueryVariables
>;
export const FindAndCountManyConstraintsByAdminDocument = gql`
  query FindAndCountManyConstraintsByAdmin($schema: FindAndCountManyClinics!) {
    findAndCountManyClinicsByAdmin(schema: $schema) {
      clinics {
        name
        constraints {
          clinicId
          id
          mailLimit
          mailSent
          smsLimit
          smsSent
        }
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyConstraintsByAdminQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyConstraintsByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyConstraintsByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyConstraintsByAdminQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyConstraintsByAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyConstraintsByAdminQuery,
    FindAndCountManyConstraintsByAdminQueryVariables
  > &
    (
      | {
          variables: FindAndCountManyConstraintsByAdminQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyConstraintsByAdminQuery,
    FindAndCountManyConstraintsByAdminQueryVariables
  >(FindAndCountManyConstraintsByAdminDocument, options);
}
export function useFindAndCountManyConstraintsByAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyConstraintsByAdminQuery,
    FindAndCountManyConstraintsByAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyConstraintsByAdminQuery,
    FindAndCountManyConstraintsByAdminQueryVariables
  >(FindAndCountManyConstraintsByAdminDocument, options);
}
export function useFindAndCountManyConstraintsByAdminSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    FindAndCountManyConstraintsByAdminQuery,
    FindAndCountManyConstraintsByAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FindAndCountManyConstraintsByAdminQuery,
    FindAndCountManyConstraintsByAdminQueryVariables
  >(FindAndCountManyConstraintsByAdminDocument, options);
}
export type FindAndCountManyConstraintsByAdminQueryHookResult = ReturnType<
  typeof useFindAndCountManyConstraintsByAdminQuery
>;
export type FindAndCountManyConstraintsByAdminLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyConstraintsByAdminLazyQuery
>;
export type FindAndCountManyConstraintsByAdminSuspenseQueryHookResult =
  ReturnType<typeof useFindAndCountManyConstraintsByAdminSuspenseQuery>;
export type FindAndCountManyConstraintsByAdminQueryResult = Apollo.QueryResult<
  FindAndCountManyConstraintsByAdminQuery,
  FindAndCountManyConstraintsByAdminQueryVariables
>;
