import { Navigate, Route, Routes } from "react-router-dom";

import NotFoundPage from "src/pages/MainPages/NotFoundPage";
import { PATHS } from "src/constants";
import { useAuthContext } from "src/context/AuthContext";
import { Box } from "@mui/material";
import { SideBar } from "src/components/Sidebar/Sidebar";
import { routes } from "./routes";

interface ProtectedRouteTypes {
  element: () => JSX.Element;
  path: string;
  isAuth: boolean;
}

export function Router() {
  const { isAuth } = useAuthContext();

  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<ProtectedRoute {...route} isAuth={isAuth} />}
        />
      ))}
      <Route key="NotFound" path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

function ProtectedRoute({
  element: Element,
  path,
  isAuth,
}: ProtectedRouteTypes) {
  if (authRoutCheck(path)) {
    if (isAuth) return <Navigate to={PATHS.clinics} />;
    return <Element />;
  }

  if (isAuth) {
    return (
      <Box>
        <SideBar>
          <Element />
        </SideBar>
      </Box>
    );
  }

  return <Navigate to={PATHS.auth} />;
}

function authRoutCheck(path: string) {
  const authRout = [PATHS.auth];
  return authRout.includes(path);
}
