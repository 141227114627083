import { IconButton, Toolbar } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuthContext } from "src/context/AuthContext";
import { AppBarStyled } from "./styles";

interface HeaderProps {
  handleDrawerOpen: () => void;
}

export const Header = ({ handleDrawerOpen }: HeaderProps) => {
  const { logout } = useAuthContext();
  return (
    <AppBarStyled position="fixed">
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="log out"
          onClick={logout}
          edge="start"
        >
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBarStyled>
  );
};
