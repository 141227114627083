import { gql } from "@apollo/client";

export const FIND_AND_COUNT_MANY_CLINICS_BY_ADMIN = gql`
  query FindAndCountManyClinicsByAdmin($schema: FindAndCountManyClinics!) {
    findAndCountManyClinicsByAdmin(schema: $schema) {
      clinics {
        phoneNumber
        name
        id
        messagingServiceSid
      }
      count
    }
  }
`;

export const FIND_AND_COUNT_MANY_CONSTRAINTS_BY_ADMIN = gql`
  query FindAndCountManyConstraintsByAdmin($schema: FindAndCountManyClinics!) {
    findAndCountManyClinicsByAdmin(schema: $schema) {
      clinics {
        name
        constraints {
          clinicId
          id
          mailLimit
          mailSent
          smsLimit
          smsSent
        }
      }
      count
    }
  }
`;
