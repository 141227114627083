import { PATHS } from "src/constants";
import LoginPage from "src/pages/AuthPages/LoginPage";
import ClinicsPage from "src/pages/MainPages/ClinicsPage/ClinicsPage";
import ConstraintsPage from "src/pages/MainPages/ConstraintsPage/ConstraintsPage";
import UpdateConstraintsPage from "src/pages/MainPages/UpdateConstraintPage/UpdateConstraintPage";
import UpdateMessagingServiceSidPage from "src/pages/MainPages/UpdateMessagingServiceSidPage/UpdateMessagingServiceSidPage";

export const routes = [
  {
    path: PATHS.auth,
    element: LoginPage,
  },
  {
    path: PATHS.clinics,
    element: ClinicsPage,
  },
  {
    path: PATHS.updateSid,
    element: UpdateMessagingServiceSidPage,
  },
  {
    path: PATHS.constraints,
    element: ConstraintsPage,
  },
  {
    path: PATHS.updateConstraints,
    element: UpdateConstraintsPage,
  },
];
