import { useState } from "react";

interface IPagination {
  limit: number;
  count?: number;
}

export const usePagination = ({ count, limit }: IPagination) => {
  const [currentPage, setCurrentPage] = useState(1);

  const pages = count ? Array.from(Array(Math.ceil(count / limit)).keys()) : [];

  const offset = currentPage * limit - limit;

  return {
    offset,
    pages,
    currentPage,
    setCurrentPage,
  };
};
