import React, { useEffect, useMemo } from "react";
import Cookies from "js-cookie";
import { client } from "src/graphql/client";
import { JWT } from "src/constants";
import { actionTypes, authReducer } from "./reducers/authReducer";

type Props = {
  children: React.ReactNode;
};

type ContextProps = {
  isAuth: boolean;
  onSuccessAuth: (stateAuth: boolean) => void;
  logout: () => void;
};

export const AuthContext = React.createContext<ContextProps>({
  isAuth: false,
  onSuccessAuth: () => {},
  logout: () => {},
});

export function useAuthContext() {
  const authContext = React.useContext(AuthContext);

  if (!authContext) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return authContext;
}

export function AuthProvider({ children }: Props) {
  const isDevelopment = process.env.REACT_APP_ENV === "develop"; // temporary condition  for development
  const isAuthDev = Cookies.get("stateAuth") === "true"; // temporary condition for development

  const [state, dispatch] = React.useReducer(authReducer, {
    isAuth: false,
    isInit: false,
  });

  const cookieJWT = Cookies.get(JWT);

  useEffect(() => {
    if (cookieJWT) {
      dispatch({
        type: actionTypes.SUCCESS_AUTH,
        payload: { stateAuth: true },
      });
    }
  }, [cookieJWT]);

  const handleSuccessAuth = (stateAuth: boolean) => {
    dispatch({
      type: actionTypes.SUCCESS_AUTH,
      payload: { stateAuth },
    });

    // temporary condition for development VVV
    if (isDevelopment) Cookies.set("stateAuth", `${stateAuth}`);
  };

  const handleLogout = () => {
    handleSuccessAuth(false);
    Cookies.remove(JWT);

    // temporary condition for development VVV
    if (isDevelopment) Cookies.remove("stateAuth");

    client.clearStore();
  };

  const value = useMemo(
    () => ({
      // temporary condition isAuthDev for development VVV
      isAuth: cookieJWT || isAuthDev || state.isAuth,
      onSuccessAuth: handleSuccessAuth,
      logout: handleLogout,
    }),
    [state.isAuth, handleSuccessAuth, handleLogout],
  );

  return (
    <AuthContext.Provider value={value as ContextProps}>
      {children}
    </AuthContext.Provider>
  );
}
