import {
  Box,
  CircularProgress,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { DEFAULT_CLINICS_LIMIT, PATHS } from "src/constants";
import {
  ClinicSortFields,
  SortingOrders,
  useFindAndCountManyClinicsByAdminLazyQuery,
  useUpdateClinicMessagingServiceSidByAdminMutation,
} from "src/graphql/generated";
import { usePagination } from "src/hooks/usePagination";
import { useNavigate } from "react-router-dom";
import { FIND_AND_COUNT_MANY_CLINICS_BY_ADMIN } from "src/graphql/queries/findAndCountManyClinicsByAdmin.gql";

function ClinicsPage() {
  const navigate = useNavigate();

  const [getFindAndCountManyClinicsLazyQuery, { data, loading }] =
    useFindAndCountManyClinicsByAdminLazyQuery();

  const [updateSidMutation, { loading: deleteLoading }] =
    useUpdateClinicMessagingServiceSidByAdminMutation();

  const { offset, currentPage, pages, setCurrentPage } = usePagination({
    limit: DEFAULT_CLINICS_LIMIT,
    count: data?.findAndCountManyClinicsByAdmin?.count || 0,
  });

  useEffect(() => {
    getFindAndCountManyClinicsLazyQuery({
      variables: {
        schema: {
          offset,
          limit: DEFAULT_CLINICS_LIMIT,
          sortOrder: SortingOrders.Descending,
          sortField: ClinicSortFields.CreatedAt,
        },
      },
      fetchPolicy: "no-cache",
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    });
  }, [currentPage]);

  const setPaginationPageHandler = (
    _: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    setCurrentPage(page);
  };

  const handleOpenUpdateSidPage = (id: string, sid: string) => () => {
    const searchParams = new URLSearchParams({
      id,
      ...(sid && { sid }),
    });
    navigate({ pathname: PATHS.updateSid, search: searchParams.toString() });
  };

  const handleDeleteSid = (id: string) => () => {
    updateSidMutation({
      variables: { schema: { id, messagingServiceSid: null } },
      refetchQueries: [FIND_AND_COUNT_MANY_CLINICS_BY_ADMIN],
      onCompleted: (response) => {
        if (response) toast.success("Successful");
      },
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    });
  };

  return (
    <Box pb="24px">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Id</TableCell>
                  <TableCell align="center">Messaging service sid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.findAndCountManyClinicsByAdmin?.clinics.map((clinic) => (
                  <TableRow
                    key={clinic.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{clinic.name}</TableCell>
                    <TableCell align="center">{clinic.id}</TableCell>
                    <TableCell align="center">
                      {clinic.messagingServiceSid}
                      <IconButton
                        onClick={handleOpenUpdateSidPage(
                          clinic.id,
                          clinic.messagingServiceSid as string,
                        )}
                      >
                        <EditIcon />
                      </IconButton>
                      {clinic.messagingServiceSid && (
                        <IconButton
                          onClick={handleDeleteSid(clinic.id)}
                          disabled={deleteLoading}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={4}>
            <Pagination
              count={pages.length}
              page={currentPage}
              onChange={setPaginationPageHandler}
              variant="outlined"
              color="primary"
            />
          </Box>
        </>
      )}
    </Box>
  );
}
export default ClinicsPage;
