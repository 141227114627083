import { VALIDATION_TEXT_ERROR } from "src/constants";
import * as yup from "yup";

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      VALIDATION_TEXT_ERROR.incorrectEmail,
    )
    .required(VALIDATION_TEXT_ERROR.fieldRequired),
  password: yup
    .string()
    .min(8, VALIDATION_TEXT_ERROR.passwordMin)
    .required(VALIDATION_TEXT_ERROR.fieldRequired),
});
